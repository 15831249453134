import DataPairGui from '../../../../gui-set/DataPair';
import SelectGui from '../../../../gui-set/Select';

/* Material UI */
import {
    TextField
} from '@mui/material';

function ClientDetails() {
    return (<>
        <DataPairGui title="Nama Pelanggan" data="Ahmed Setiawan"/>
        <DataPairGui title="Paket" data="50 mbps"/>
    </>);
}

function ClientForm() {
    return (<>
        <TextField
            label="Nama Pelanggan"
            variant="outlined"
            size="small"
        />
        <SelectGui
            name="Paket"
            namesList={[
                '10 mbps',
                '25 mbps',
                '50 mbps',
                '100 mbps'
            ]}
            noIcon
        />
    </>);
}

export {
    ClientDetails,
    ClientForm
};

