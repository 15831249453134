import ParentChildStatus from "./parent-child-status";
import * as ValidateValue from "../validate-value";

/**
 * This suitable for check box of cards list.
 */
export default class CheckBoxStatus extends ParentChildStatus {

    /**
     * @param {object} depths - Object of indexes array (from 'Depths.js')
     */
    constructor(depths) {
        super(depths);
        this.selectedItems = [];
    }

    /**
     * The 'flag_in' must be a number, not a boolean.
     * This is following default 'intermediateFlag'.
     * 
     * @param {number} flag_in 
     * @param {number[]} depth_in 
     * @param {*} item_in - Could be a placemark
     * @param {number} intermediateFlag 
     */
    set(flag_in, depth_in, item_in, intermediateFlag = 1) {
        super.set(flag_in, depth_in, true, intermediateFlag);
        this.setSelectedItems(flag_in, item_in);
    }

    /**
     * Empty all items and status flags.
     */
    setEmpty() {
        super.set(0, [0], true);
        this.selectedItems = [];
    }

    /**
     * @param {number[]} depth_in
     * @param {*} item_in - Could be a placemark
     * @param {boolean} alsoChildren
     */
    drop(depth_in, alsoChildren, item_in) {
        super.drop(depth_in, alsoChildren);
        this.setSelectedItems(false, item_in);
    }

    /**
     * @param {boolean} flag_in 
     * @param {*} item_in - Could be a placemark
     * @returns {void}
     */
    setSelectedItems(flag_in, item_in) {
        if (flag_in) {
            this.selectedItems.push(item_in);
        }
        else {
            for (let i = 0; i < this.selectedItems.length; i++) {
                if (this.selectedItems[i] === item_in) {
                    this.selectedItems = ValidateValue.cutArray(this.selectedItems, i);
                }
            }
        }
    }
};

