import DataPairGui from '../../../../gui-set/DataPair';
import SelectGui from '../../../../gui-set/Select';

/* Material UI */
import {
    TextField
} from '@mui/material';

function CableDetails() {
    return (<>
        <DataPairGui title="Jenis" data="Akses"/>
        <DataPairGui title="Kapasitas" data="24 core"/>
        <DataPairGui title="Panjang Laporan" data="2000 meter"/>
        <DataPairGui title="Panjang 2D Otomatis" data="1950 meter"/>
    </>);
}

function CableForm() {
    return (<>
        <SelectGui
            name="Jenis"
            namesList={[
                'Akses',
                'Backbone',
                'Backhaul'
            ]}
            noIcon
        />
        <SelectGui
            name="Kapasitas"
            namesList={[
                '6 core',
                '12 core',
                '24 core',
                '48 core',
                '96 core'
            ]}
            noIcon
        />
        <TextField
            label="Panjang Laporan"
            variant="outlined"
            size="small"
            sx={{ width: '250px' }}
        />
        <TextField
            label="Panjang 2D Otomatis"
            variant="filled"
            size="small"
            value="2000 meter"
            InputProps={{
                readOnly: true
            }}
            sx={{ width: '250px' }}
        />
    </>);
}

export {
    CableDetails,
    CableForm
};

