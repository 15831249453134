import * as React from 'react';

/** Executions */
import AbstractExecution from './actions-execution/abstract';
import DeleteExecution from './actions-execution/delete';
import DownloadExecution from './actions-execution/download';
import FilterExecution from './actions-execution/filter';
import SortExecution from './actions-execution/sort';
import UploadExecution from './actions-execution/upload';
import IconButtonGui from '../../gui-set/IconButton';

/** Utilities */
import NumbersAbbreviation from '../../gui-set/NumberAbbreviation';
import * as IdGenerator from '../../utilities/id-generator';
import * as CenterFlex from '../../utilities/center-flex';
import CheckBoxStatus from '../../utilities/status/check-box-status';
import RerenderList from '../../utilities/status/rerender-list';

/** Material UI */
import { Box, Checkbox } from '@mui/material';
import { grey } from '@mui/material/colors';

/** MUI ICONS */
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/Download';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import MapIcon from '@mui/icons-material/Map';
import SortIcon from '@mui/icons-material/Sort';
import UploadIcon from '@mui/icons-material/Upload';

/**
 * Create 'IconButtonGui' components.
 * 
 * @param {object[]} icons - MUI icons
 * @param {string} width - in pixel
 * @returns {object[]} Components Array
 */
function createIconButtonsArray(icons, width) {

    const iconKeys = IdGenerator.getGroup(6),
          retArr = [];

    let ctr = 0;

    // cannot using array access syntax in JSX
    for (const IconIter of icons) {

        retArr.push(<IconButtonGui
            key={iconKeys[ctr]}
            icon={<IconIter sx={{ width: width }}/>}
            diameter={width}
            onClick={() => {}}
        />);

        ctr++;
    }

    return retArr;
}

/**
 * Action buttons to interact with placemark or container
 * 
 * @param {object} props 
 * @param {CheckBoxStatus} props.checkBoxStatus 
 * @param {RerenderList} props.cardRerenderState 
 * @param {object} props.floatingContainer 
 * @returns {object} React Component
 */
export default function ActionsButton(props) {

    let itemIcons = [
        MapIcon,
        FilterAltIcon,
        SortIcon,
        UploadIcon,
        DownloadIcon,
        DeleteIcon
    ];

    let names = [
        'Tanda letak banyak',
        'Saring',
        'Urutkan',
        'Gabung ke data utama',
        'Unduh',
        'Hapus'
    ];

    let callbacks = [
        AbstractExecution,
        FilterExecution,
        SortExecution,
        UploadExecution,
        DownloadExecution,
        DeleteExecution
    ];

    const availableActions = [
        true,  // props.abstract
        true,  // props.filter
        true,  // props.sort
        true,  // props.upload
        true,  // props.download
        true   // props.delete
    ];

    // to clean empty action items
    const getPassData = (actionItems) => {
        const usedActions = [];

        for (let i = 0; i < availableActions.length; i++) {
            if (availableActions[i]) {
                usedActions.push(actionItems[i]);
            }
        }

        return usedActions;
    };

    // existence filter
    itemIcons = getPassData(itemIcons);
    names = getPassData(names);
    callbacks = getPassData(callbacks);

    /** FLAGS */

    let iconPx = '16px';
    const availableItems = props.checkBoxStatus.depths.current.length();

    // number of selected placemarks and their descendants
    const totalSelected = props.checkBoxStatus.activeCount;

    // all placemarks selected
    const isAllSelected = totalSelected === availableItems;

    // neither all placemarks selected nor empty
    const isIndeterminate = (
        totalSelected > 0 &&
        totalSelected < availableItems
    );

    // 0 'no unit', 1 'letter unit', 2 'power 10 unit'
    let pow10Rate;

    if (totalSelected >= Math.pow(10, 15)) {
        pow10Rate = 2;
        iconPx = '14px';
    }
    else if (totalSelected >= 1000) {
        pow10Rate = 1;
        iconPx = '15px';
    }
    else pow10Rate = 0;

    // convert to buttons from icons array
    itemIcons = createIconButtonsArray(itemIcons, iconPx);

    /** COMPONENT */

    if (totalSelected > 0) {
        return <Box sx={CenterFlex.combine({
            width: '100%',
            height: '30px',
            borderRadius: '8px',
            backgroundColor: 'white',
            gap: pow10Rate === 2 ? 0 : (pow10Rate === 1 ? '2px' : '4px')
        })}>
            <Box sx={CenterFlex.combine({
                height: '100%',
                paddingRight: '2px',
                boxSizing: 'border-box'
            })}>
                <Checkbox
                    size="small"
                    checked={isAllSelected}
                    indeterminate={isIndeterminate}
                    onClick={() => {
                        props.checkBoxStatus.setEmpty();
                        props.cardRerenderState.current[1]();
                    }}
                    sx={{
                        padding: 0,
                        color: grey[800],
                        '& .MuiSvgIcon-root': { fontSize: iconPx },
                        '&.Mui-checked': { color: grey[600] },
                        '&.MuiCheckbox-indeterminate': { color: grey[600] }
                    }}
                />
                <NumbersAbbreviation
                    number={totalSelected}
                    floatingContainer={props.floatingContainer}
                />
            </Box>
            { itemIcons }
        </Box>;
    }
    else return <></>;
}

