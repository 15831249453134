/**
 * Customizable center flex property.
 * 
 * @param {object} objParam 
 * @param {boolean} objParam.alignCenter 
 * @param {boolean} objParam.justifyCenter 
 * @returns {object} CSS Object
 */
function get(objParam) {
    let allToBoolean = false;

    if (typeof objParam !== 'object' ||
        Array.isArray(objParam)
    ) {
        allToBoolean = true;
        objParam = { alignCenter: true, justifyCenter: true };
    }

    if (!allToBoolean) {
        if (typeof objParam.alignCenter !== 'boolean') {
            objParam.alignCenter = true;
        }

        if (typeof objParam.justifyCenter !== 'boolean') {
            objParam.justifyCenter = true;
        }
    }

    return {
        display: 'flex',
        alignItems: objParam.alignCenter ? 'center' : 'flex-start',
        justifyContent: objParam.justifyCenter ? 'center' : 'flex-start'
    };
}

/**
 * Combine center flex with other CSS properties.
 * 
 * @param {object} objParam 
 * @param {object} objParam.flexCustom 
 * @param {object} objParam.properties 
 * @returns {object} CSS Object
 */
function combine(objParam) {

    if (!objParam.flexCustom) {
        if (!objParam.properties) {
            return Object.assign( get(), objParam );
        }
        else objParam.flexCustom = get();
    }
    else objParam.flexCustom = get(objParam.flexCustom);

    if (!objParam.properties ||
        typeof objParam.properties !== 'object' ||
        Array.isArray(objParam.properties)
    ) {
        objParam.properties = {};
    }

    return Object.assign(
        objParam.flexCustom,
        objParam.properties
    );
}

export { get, combine };

