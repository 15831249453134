import { Box } from '@mui/material';
import { combine } from '../utilities/center-flex';
import { isObject } from '../utilities/validate-value';

/**
 * @param {object} props 
 * @param {object} props.sx - custom style
 * @returns {object} React Component
 */
export default function FloatingBoard(props) {

    let customSx = props.sx;
    if (!isObject(props.sx)) customSx = {};

    return <Box
        sx={Object.assign(
            combine({
                position: 'fixed',
                flexDirection: 'column',
                borderRadius: '10px',
                backgroundColor: 'white',
                boxShadow: '4px 4px 4px #00000088',
                zIndex: 1
            }),
            customSx
        )}
        onClick={(ev) => { ev.stopPropagation(); }}
    >
        {props.children}
    </Box>;
}

FloatingBoard.defaultProps = { sx: {} };

