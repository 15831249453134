import * as React from 'react';
import SwitchGui from '../../../gui-set/Switch';

/* Material UI */
import {
    Box,
    Button,
    FormGroup
} from '@mui/material';

/**
 * props: {
 *   setVersatileMode ( callback('details', 'form', 'import') )
 *   setImportStatus (callback)
 * }
 */
export default function Import(props) {
    return <Box sx={{
        minHeight: '300px',
        padding: '20px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        gap: '100px',
        borderRadius: '0 0 10px 10px',
        backgroundColor: 'white',
        boxSizing: 'border-box'
    }}>
        <FormGroup sx={{ gap: '10px' }}>
            <SwitchGui
                description="Pertahankan ikon tanda letak dari dokumen."
                uncheckedMessage="Saat ini ikon tata letak akan mengikuti klasifikasi sistem. (MASIH DALAM PENGEMBANGAN)"
                defaultChecked
            />
            <SwitchGui
                description="Deteksi tanggal dan waktu dari deskripsi tanda letak."
                uncheckedMessage="Saat ini tanggal diperoleh dari waktu pengimporan dokumen."
            />
        </FormGroup>
        <Box sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
            gap: '10px'
        }}>
            <Button
                variant="outlined"
                size="small"
                sx={{
                    width: '180px',
                    height: '40px'
                }}
                onClick={() => {
                    props.setImportStatus('keep');
                    props.setVersatileMode('details');
                }}
            >
                Tampilkan Sementara
            </Button>
            <Button
                variant="outlined"
                size="small"
                color="success"
                sx={{
                    width: '150px',
                    height: '40px'
                }}
                onClick={() => {
                    props.setImportStatus('save');
                    props.setVersatileMode('details');
                }}
            >
                Simpan Permanen
            </Button>
            <Button
                variant="outlined"
                color="error"
                sx={{
                    width: '100px',
                    height: '40px'
                }}
                onClick={() => {
                    props.setImportStatus('cancel');
                    props.setVersatileMode('details');
                }}
            >
                Batalkan
            </Button>
        </Box>
    </Box>;
}

