const flags = [
    'cable',
    'coil',
    'pole',
    'closure',
    'odp',
    'hh',
    'client',
    'odc',
    'pop',
    'other'
];

const localization = {
    en: [
        'Cable',
        'Coil',
        'Pole',
        'Closure',
        'ODP',
        'Handhole',
        'Client',
        'ODC',
        'POP',
        'Other Data'
    ],
    id: [
        'Kabel',
        'Coil',
        'Tiang',
        'Closure',
        'ODP',
        'Handhole',
        'Client',
        'ODC',
        'POP',
        'Data Lain'
    ]
};

function getIconUrl(type) {
    switch(type) {
        case 'cable':    return 'https://maps.google.com/mapfiles/kml/shapes/arrow-reverse.png';
        case 'coil':     return 'https://maps.google.com/mapfiles/kml/pushpin/red-pushpin.png';
        case 'pole':     return 'https://maps.google.com/mapfiles/kml/pushpin/blue-pushpin.png';
        case 'closure':  return 'https://maps.google.com/mapfiles/kml/pushpin/ylw-pushpin.png';
        case 'odp':      return 'https://maps.google.com/mapfiles/kml/shapes/placemark_square.png';
        case 'hh':       return 'https://maps.google.com/mapfiles/kml/paddle/H.png';
        case 'client':   return 'https://maps.google.com/mapfiles/kml/pushpin/wht-pushpin.png';
        case 'odc':      return 'https://maps.google.com/mapfiles/kml/shapes/campground.png';
        case 'pop':      return 'https://maps.google.com/mapfiles/kml/shapes/ranger_station.png';
        default:         return 'https://maps.google.com/mapfiles/kml/shapes/info-i.png';
    }
}

/* 'ISO_639_1_code' for example: 'en', 'id' */
function getTranslatedTitle(type, ISO_639_1_code) {
    let isValidCode = false;

    for (const key in localization) {
        if (ISO_639_1_code === key) {
            isValidCode = true;
            break;
        }
    }

    if (!isValidCode) ISO_639_1_code = 'en';

    for (let i = 0; i < flags.length; i++) {
        if (type === flags[i]) {
            return localization[ISO_639_1_code][i];
        }
    }

    return '';
}

function getIndexOrder(type) {
    for (let i = 0; i < flags.length; i++) {
        if (type === flags[i]) return i;
    }
    return -1;
}

export {
    flags,
    localization,
    getIconUrl,
    getTranslatedTitle,
    getIndexOrder
};

