/**
 * HAVERSINE FORMULA
 * 
 * @param {object} coor_1
 * @param {number} coor_1.lat 
 * @param {number} coor_1.lng 
 * @param {object} coor_2
 * @param {number} coor_2.lat 
 * @param {number} coor_2.lng 
 * @returns {number} distance in meters
 */
function getDistance(coor_1, coor_2) {

    const PI_BY_180 = Math.PI / 180;
    const R = 6371000; // meters

    /* This from Wikipedia */

    const distance = (
        2 * R * Math.asin(Math.sqrt(
            Math.pow(
                Math.sin(( coor_2.lat - coor_1.lat ) / 2 * PI_BY_180),
                2
            ) +
            Math.cos(coor_1.lat * PI_BY_180) *
            Math.cos(coor_2.lat * PI_BY_180) *
            Math.pow(
                Math.sin(( coor_2.lng - coor_1.lng ) / 2 * PI_BY_180),
                2
            )
        ))
    );

    // meters
    return distance;
}

/**
 * Using 'getDistance' repeatedly for multiple coors (more than 2).
 * [coor_1 -> coor_2, coor_2 -> coor_3, ...]
 * 
 * @param {'[{lat: number, lng: number}, ...]'} coors 
 * @returns {number} distance in meters
 */
function getMultiDistance(coors) {

    if (Array.isArray(coors) &&
        coors.length >= 2
    ) {
        let distanceAccumulation = 0;

        for (let i = 0; i < coors.length; i++) {
            if (i > 0) {
                distanceAccumulation += (
                    getDistance(coors[i - 1], coors[i])
                );
            }
        }

        return distanceAccumulation;
    }
    
    return 0;
}

export {
    getDistance,
    getMultiDistance
};

