import * as ValidateValue from './validate-value';

/**
 * Array with enhanced capabilities.
 */
export default class ArrayMod {

    /**
     * Pass only 'initValue' to directly set it as 'queue' array.
     * Specify 'count' to set repeated data ('initValue' as array will be duplicated).
     * 
     * @param {*[]} initArray 
     * @param {number} count 
    */
    constructor(initValue, count = 0) {

        this.queue = [];
        this.recentlyAdded = false;
        this.recentlyReduced = false;

        if (count === 0 && Array.isArray(initValue)) {
            this.queue = initValue;
            return;
        }

        if (count < 1) count = 1;

        for (let i = 0; i < count; i++) {
            this.queue.push(initValue);
        }
    }

    push(value) {
        this.queue.push(value);
        this.setFlags(true, false);
    }

    pop() {
        this.queue.pop();
        this.setFlags(false, true);
    }

    front() { return this.queue[0]; }
    back() { return this.queue[this.queue.length - 1]; }
    length() { return this.queue.length; }

    /**
     * @param {number} index 
     * @param {*} value 
     */
    set(index, value) {
        this.queue[index] = value;
    }

    /**
     * @param {number} index 
     * @param {boolean} defaultAsBack 
     * @returns {*}
     */
    get(index, defaultAsBack = false) {
        if (defaultAsBack && index >= this.queue.length) return this.back();
        return this.queue[index];
    }

    /**
     * Similar to 'pop' but can remove
     * members in the middle of the 'queue'.
     * 
     * @param {number} from 
     * @param {number} from 
     */
    drop(from, to) {
        if (ValidateValue.isNumber(from) &&
            !ValidateValue.isNumber(to)
        ) { to = from; }
        else if (
            !ValidateValue.isNumber(from) &&
            ValidateValue.isNumber(to)
        ) { from = to; }

        if (ValidateValue.isNumber(from) &&
            ValidateValue.isNumber(to)
        ) {
            this.queue = this.queue.slice(0, from).concat( this.queue.slice(to + 1) );
            this.setFlags(false, true);
        }
    }

    /**
     * @param {boolean} fg_add 
     * @param {boolean} fg_reduce 
     */
    setFlags(fg_add = false, fg_reduce = false) {
        this.recentlyAdded = fg_add;
        this.recentlyReduced = fg_reduce;
    }

    resetFlags() {
        this.recentlyAdded = false;
        this.recentlyReduced = false;
    }
};

