import DataPairGui from '../../../../gui-set/DataPair';
import SelectGui from '../../../../gui-set/Select';

function PoleDetails() {
    return (
        <DataPairGui title="Tinggi" data="7 meter"/>
    );
}

function PoleForm() {
    return (
        <SelectGui
            name="Tinggi"
            width="100px"
            namesList={[
                '6 meter',
                '7 meter',
                '9 meter',
                '12 meter'
            ]}
            noIcon
        />
    );
}

export {
    PoleDetails,
    PoleForm
};

