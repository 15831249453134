import * as IdGenerator from './id-generator';
import * as VV from './validate-value';

/** Marker's Extended Data */
export default class Placemark {
    /**
     * @param {object} param 
     * @param {object} param.id 
     * @param {string} param.name 
     * @param {string} param.type 
     * @param {object} param.time 
     * @param {string} param.time.date 
     * @param {string} param.time.hour 
     * @param {object} param.coor 
     * @param {number} param.coor.lat 
     * @param {number} param.coor.lng 
     * @param {string} param.note 
     * @param {string[]} param.imagesUrl 
     * @param {object} param.client 
     * @param {object} param.client.parent 
     * @param {object[]} param.client.children 
     * @param {object[]} param.client.friends 
     * @param {string} param.client.customIconUrl 
     * @param {string} param.client.cardOrder - This usually specified in 'Card'
     * @param {number[]} param.client.depth 
     * @param {object} rawPlc - This is generally a database placemark
     */
    constructor(param, rawPlc = null) {
        this.client = {};

        if (rawPlc) {
            this.id = rawPlc._id;
            this.name = rawPlc.name;
            this.type = rawPlc.type;
            this.time = rawPlc.time;
            this.coor = rawPlc.coor;
            this.note = rawPlc.note;
            this.imagesUrl = rawPlc.imagesUrl;
            this.client.isPseudo = false;
        }
        else if (param) {
            let isAnyExist = false;

            const pass = (value) => {
                if (!isAnyExist) isAnyExist = true;
                return value;
            }

            this.id = param.id ? pass(param.id) : undefined;
            this.name = param.name ? pass(param.name) : '';
            this.type = param.type ? pass(param.type) : 'other';
            this.time = param.time ? pass(param.time) : {date: '', hour: ''};
            this.coor = param.coor ? pass(param.coor) : {lat: 0.00, lng: 0.00};
            this.note = param.note ? pass(param.note) : '';
            this.imagesUrl = param.imagesUrl ? pass(param.imagesUrl) : [];

            this.client.isPseudo = !isAnyExist;
        }
        else {
            this.id = undefined;
            this.name = '';
            this.type = 'other';
            this.time = {date: '', hour: ''};
            this.coor = {lat: 0.00, lng: 0.00};
            this.note = '';
            this.imagesUrl = [];
            this.client.isPseudo = true;
        }

        /**
         * CLIENT
         * This is not an ISP client.
         * But intended as user local data.
         */

        if (param && param.client) {
            Object.assign(
                this.client,
                {
                    parent: param.client.parent ? param.client.parent : null,
                    children: param.client.children ? param.client.children : [],
                    friends: param.client.friends ? param.client.friends : [],
                    customIconUrl: param.client.customIconUrl ? param.client.customIconUrl : '',
                    cardOrder: param.client.cardOrder ? param.client.cardOrder : 0,
                    depth: param.client.depth ? param.client.depth : [],
                    approved: false
                }
            );
        }
        else Object.assign(
            this.client,
            {
                parent: null,
                children: [],
                friends: [],
                customIconUrl: '',
                cardOrder: undefined,
                depth: [],
                approved: false
            }
        );

        /** ID */

        // set 'id' without duplication
        if (!VV.isString(this.id) && !VV.isNumber(this.id)) {
            let idGen;

            // placemark friends
            if (param && param.client &&
                Array.isArray(param.client.friends)
            ) {
                idGen = IdGenerator.getSingle();

                for (let i = 0; i < param.client.friends.length; i++) {
                    if (param.client.friends[i] &&
                        param.client.friends[i].id === idGen
                    ) {
                        i = 0;
                        idGen = IdGenerator.getSingle();
                    }
                }
            }
            // no friend
            else idGen = IdGenerator.getSingle(9);

            this.id = idGen;
        }
    }

    /**
     * Check whether 'this' has children or not.
     * @returns {number} Number of indexes
     */
    hasChildren() {
        return this.client.children.length;
    }

    /**
     * Remove the placemark and its children from
     * 'placemarksDataState (React useState)'.
     * 
     * @param {object} placemarksDataState 
     * @param {boolean} isRecursive 
     * @returns {void} 
     */
    remove(placemarksDataState, isRecursive = false) {

        // cut 'placemarksDataState'
        const cutPlcsData = (index) => {
            placemarksDataState.setter(
                VV.cutArray(
                    placemarksDataState.ref.current.data,
                    index
                )
            );
        };
    
        let isThisRemoved = false;
    
        for (let i = 0; i < placemarksDataState.ref.current.data.length; i++) {
            if (placemarksDataState.ref.current.data[i] === this) {
                if (!isThisRemoved) isThisRemoved = true;
    
                cutPlcsData(i);
                this.client.parent = null;
    
                if (this.client.children.length === 0) {
                    break;
                }
                else i--;
            }
            else {
                for (let j = 0; j < this.client.children.length; j++) {
                    if (placemarksDataState.ref.current.data[i] === this.client.children[j]) {
    
                        if (this.client.children[j].client.children.length > 0) {
                            this.client.children[j].remove(placemarksDataState, true);
                        }
                        else {
                            this.client.children = VV.cutArray(this.client.children, j);
                            cutPlcsData(i);
                        }
    
                        i--;
                        break;
                    }
                }
            }
        }
    
        if (isThisRemoved) {
            this.client.children = [];
        }
    
        if (!isRecursive) {
            placemarksDataState.update();
        }
    }
};

