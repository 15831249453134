import DataPairGui from '../../../../gui-set/DataPair';
import SelectGui from '../../../../gui-set/Select';

function PopDetails() {
    return (
        <DataPairGui title="Perangkat Aktif" data="Raisecom"/>
    );
}

function PopForm() {
    return (
        <SelectGui
            name="Perangkat Aktif"
            namesList={[
                'Raisecom',
                'Huawei',
                'Nexus',
                'Cisco'
            ]}
            noIcon
        />
    );
}

export {
    PopDetails,
    PopForm
};

