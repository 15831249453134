import * as React from 'react';
import Details from "./versatile/Details";
import Form from "./versatile/Form";
import Import from "./versatile/Import";
import ShadeShield from '../../gui-set/ShadeShield';
import './Versatile.css';

/**
 * @param {object} props 
 * @param {object} props.domSet 
 * @param {object} props.modeState 
 * @param {string} props.drawType - Placemark type
 * @param {object} props.shadeShieldGlobalState 
 * @param {object[]} props.selectedPlacemarkState - react 'useState' pair
 * @param {Function} props.setImportStatus 
 * @param {object} props.formInputValue - Attributes: { special, general }
 * @param {Function} props.increaseFormInputChanged 
 * @returns {object} React Component
 */
export default function Versatile(props) {
    const container = React.useRef(null);

    React.useEffect(() => {
        props.domSet.current.add(
            'Versatile',
            container.current
        );
    }, []);

    let UsedComponent = <></>;
    
    if (props.modeState.value === 'details') {
        UsedComponent = <Details
            selectedPlacemarkState={props.selectedPlacemarkState}
            empty={props.selectedPlacemarkState[0] ? false : true}
        />
    }
    else if (props.modeState.value === 'form') {
        UsedComponent = <Form
            type={props.drawType}
            versatileDomRef={container}
            inputValue={props.formInputValue}
            increaseInputChanged={props.increaseFormInputChanged}
        />;
    }
    else if (props.modeState.value === 'import') {
        UsedComponent = <Import
            setVersatileMode={props.modeState.setter}
            setImportStatus={props.setImportStatus}
        />;
    }

    return <div className="Options-Versatile" ref={container}>
        {UsedComponent}
        {props.shadeShieldGlobalState.value ?
            <ShadeShield
                container={container.current}
                topComponents={props.domSet.current.multiQuery( ['Account', 'Draw'] )}
                leftComponents={props.domSet.current.multiQuery( ['List', 'Map'] )}
                gaps={[15, 10, 5, 0]}
                offsetCovered
            /> :
            <></>
        }
    </div>;
}

