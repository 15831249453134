import DataPairGui from '../../../../gui-set/DataPair';
import SelectGui from '../../../../gui-set/Select';

function OdcDetails() {
    return (
        <DataPairGui title="Kapasitas" data="48 core"/>
    );
}

function OdcForm() {
    return (
        <SelectGui
            name="Kapasitas"
            namesList={[
                '6 core',
                '12 core',
                '24 core',
                '48 core',
                '96 core'
            ]}
            noIcon
        />
    );
}

export {
    OdcDetails,
    OdcForm
};

