import { useState } from 'react';
import * as IdGenerator from '../utilities/id-generator';

/* Material UI */
import {
    Box,
    FormControl,
    InputLabel,
    MenuItem,
    Select
} from '@mui/material';

/* 'nameList' must be different each other */
function getIndexFromNames(nameList, nameTest) {
    for (let i = 0; i < nameList.length; i++) {
        if (nameList[i] === nameTest) {
            return i;
        }
    }
}

/**
 * @param {object} props 
 * @param {string} props.name 
 * @param {string} props.width - Can be string or number
 * @param {string} props.titleFontSize - Can be string or number
 * @param {string[]} props.namesList 
 * @param {Function[]} props.tasksList 
 * @param {Function} props.task 
 * @param {object} props.icon - MUI Icon
 * @param {boolean} props.isFilled 
 * @param {boolean} props.noIcon 
 * @param {boolean} props.displayEmpty 
 * @returns {object} React Component
 */
export default function SelectGui(props) {

    /**
     *  WARNING!
     *  Consider to set 'props.namesList' uniquely
     *  because if there is duplicate,
     *  the first one's task callback will always called
     *  regardless the other selected
     * 
     *  --refer to this 'getIndexFromNames' function--
     */

    const [selected, setSelected] = useState('');

    const menuItems = [];
    const menuItemsKeys = IdGenerator.getGroup(props.namesList.length);

    for (let i = 0; i < props.namesList.length; i++) {
        menuItems.push(
            <MenuItem
                value={props.namesList[i]}
                key={menuItemsKeys[i]}
            >
                {props.namesList[i]}
            </MenuItem>
        );
    }

    return (
        <FormControl
            variant={props.isFilled ? 'filled' : 'outlined'}
            fullWidth
            size="small"
            sx={{ width: props.width ? props.width : 1 }}
        >
            <InputLabel id={`${props.name}Label`}>
                <FormControl sx={{ flexDirection: 'row', gap: '4px' }}>
                    {props.icon}
                    <Box sx={{ fontSize: props.titleFontSize }}>
                        {props.name}
                    </Box>
                </FormControl>
            </InputLabel>
            <Select
                labelId={`${props.name}Label`}
                value={selected}
                label={`${props.noIcon ? '' : 'xxx'}${props.name}`}
                displayEmpty={props.displayEmpty}
                onChange={(ev) => {

                    setSelected(ev.target.value);
                    let selectedIndex = getIndexFromNames(props.namesList, ev.target.value);

                    /**
                     * EXAMPLE:
                     *   task={(value, clearValue) => {
                     *      clearValue();
                     *   }}
                     */

                    const clearValue = () => { setSelected(''); };

                    if (props.task) {
                        props.task(ev.target.value, clearValue);
                    }

                    if (Array.isArray(props.tasksList)) {
                        props.tasksList[selectedIndex](ev.target.value, clearValue);
                    }
                }}
            >
                {menuItems}
            </Select>
        </FormControl>
    );
}

