import * as React from 'react';
import Result from './list/Result';
import ActionsButton from './list/Actions';
import SearchBox from './list/Search';
import ShadeShield from '../gui-set/ShadeShield';
import './List.css';

/* Material UI */
import {
    Box,
    Typography
} from '@mui/material';

/* MUI Icons */
import CloudOutlinedIcon from '@mui/icons-material/CloudOutlined';
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import ListIcon from '@mui/icons-material/List';
import RerenderList from '../utilities/status/rerender-list';

/**
 * @param {object} props 
 * @param {object} props.domSet 
 * @param {object} props.floatingContainer 
 * @param {object} props.shadeShieldGlobalState 
 * @param {object} props.mainDataState 
 * @param {object} props.KMLDataState 
 * @param {Function} props.setSelectedPlacemark 
 * @param {object} props.cardsOpenStatus 
 * @param {object} props.checkBoxStatus 
 * @param {object} props.selectedCardColorStatus 
 * @param {RerenderList} props.upwardCardRerenderList 
 * @returns {object} React Component
 */
export default function List(props) {

    /** Card Re-render */
    const cardRerenderState = new RerenderList(
        React.useState(false),
        props.upwardCardRerenderList
    );

    const container = React.useRef(null),
          mainIconSx = {width: '14px', height: '14px'};

    React.useEffect(() => {
        props.domSet.current.add('List', container.current);
    }, []);

    /**
     *  'ResultDisplayed' flags:
     *  0 = void
     *  1 = empty
     *  2 = display
     */

    const [mainResultComponent, setMainResultComponent] = React.useState(<></>),
          mainResultDisplayed = React.useRef(0);

    const [KMLResultComponent, setKMLResultComponent] = React.useState(<></>),
          KMLResultDisplayed = React.useRef(0);

    /* MAIN RESULT */
    React.useEffect(() => {
        const plcsStateLength = props.mainDataState.ref.current.data.length;

        if (plcsStateLength > 1 ||
            (plcsStateLength > 0 &&
            !props.mainDataState.ref.current.data[0].client.isPseudo)
        ) {
            mainResultDisplayed.current = 2;
            
            setMainResultComponent(<Result
                key="main-result"
                mainTitle="Data Utama"
                mainIcon={<CloudOutlinedIcon sx={mainIconSx}/>}
                placemarksDataState={props.mainDataState}
                setSelectedPlacemark={props.setSelectedPlacemark}
                cardsOpenStatus={props.cardsOpenStatus}
                checkBoxStatus={props.checkBoxStatus}
                selectedCardColorStatus={props.selectedCardColorStatus}
                upwardCardRerenderList={cardRerenderState}
            />);

            if (KMLResultDisplayed.current === 1) {
                setKMLResultComponent(<></>);
                KMLResultDisplayed.current = 0;
            }
        }
        else if (KMLResultDisplayed.current === 0) {
            setMainResultComponent(<EmptyDisplay/>);
            mainResultDisplayed.current = 1;
        }
        else {
            setMainResultComponent(<></>);
            mainResultDisplayed.current = 0;
        }
    }, [props.mainDataState.updated, cardRerenderState.current[0]]);

    /* KML RESULT */
    React.useEffect(() => {
        const plcsStateLength = props.KMLDataState.ref.current.data.length;

        if (plcsStateLength > 1 ||
            (plcsStateLength > 0 &&
            !props.KMLDataState.ref.data.current[0].client.isPseudo)
        ) {
            KMLResultDisplayed.current = 2;

            setKMLResultComponent(<Result
                key="kml-result"
                mainTitle="Unggahan KML"
                mainIcon={<FolderOutlinedIcon sx={mainIconSx}/>}
                placemarksDataState={props.KMLDataState}
                setSelectedPlacemark={props.setSelectedPlacemark}
                cardsOpenStatus={props.cardsOpenStatus}
                checkBoxStatus={props.checkBoxStatus}
                selectedCardColorStatus={props.selectedCardColorStatus}
                upwardCardRerenderList={cardRerenderState}
            />);

            if (mainResultDisplayed.current === 1) {
                setMainResultComponent(<></>);
                mainResultDisplayed.current = 0;
            }
        }
        else if (mainResultDisplayed.current === 0) {
            setKMLResultComponent(<EmptyDisplay/>);
            KMLResultDisplayed.current = 1;
        }
        else {
            setKMLResultComponent(<></>);
            KMLResultDisplayed.current = 0;
        }
    }, [props.KMLDataState.updated, cardRerenderState.current[0]]);

    /* RETURN COMPONENT */

    return <div className="List" ref={container}>
        <SearchBox/>
        <ActionsButton
            checkBoxStatus={props.checkBoxStatus}
            cardRerenderState={cardRerenderState}
            floatingContainer={props.floatingContainer}
        />
        <div className="List-Result">
            <div className="List-2-Result">
                { mainResultComponent }
                { KMLResultComponent }
            </div>
        </div>
        {props.shadeShieldGlobalState.value ?
            <ShadeShield container={container.current}/> : <></>
        }
    </div>;
}

function EmptyDisplay() {
    return <Box sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '30px'
    }}>
        <ListIcon sx={{
            transform: 'scale(3)',
            opacity: 0.25
        }}/>
        <Typography
            variant="h5"
            align="center"
            color="#999999"
        >
            Daftar akan <br/> muncul disini
        </Typography>
    </Box>;
}

