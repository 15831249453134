import * as React from 'react';

/* Material UI */
import {
    FormControlLabel,
    Switch,
    Typography
} from '@mui/material';

/**
 * Switch or toggle button.
 * 
 * @param {string} props.description 
 * @param {string} props.uncheckedMessage 
 * @param {string} props.checkedMessage 
 * @param {boolean} props.defaultChecked 
 * @returns {object} React Component
 */
export default function SwitchGui(props) {

    const [checkedWarnString, setCheckedWarnString] = React.useState(
        props.defaultChecked ? props.checkedMessage : ''
    );

    const [uncheckedWarnString, setUncheckedWarnString] = React.useState(
        !props.defaultChecked ? props.uncheckedMessage : ''
    );

    const warnSx = {
        fontSize: '12px',
        color: 'red'
    };

    return <FormControlLabel
        control={<Switch defaultChecked={props.defaultChecked}/>}
        sx={{ alignItems: 'flex-start' }}
        label={<>
            <Typography>
                {props.description}
            </Typography>
            {props.checkedMessage !== '' && checkedWarnString !== '' ?

                /* CHECKED STRING */
                <Typography sx={warnSx}>
                    {checkedWarnString}
                </Typography> : (

                props.uncheckedMessage !== '' && uncheckedWarnString !== '' ?

                /* UNCHECKED STRING */
                <Typography sx={warnSx}>
                    {uncheckedWarnString}
                </Typography>
                : <></>
            )}
        </>}
        onChange={(ev) => {
            if (ev.target.checked) {

                if (props.checkedMessage !== '') {
                    setCheckedWarnString(props.checkedMessage);
                }

                if (props.uncheckedMessage !== '') {
                    setUncheckedWarnString('');
                }
            }
            else {
                if (props.checkedMessage !== '') {
                    setCheckedWarnString('');
                }
                
                if (props.uncheckedMessage !== '') {
                    setUncheckedWarnString(props.uncheckedMessage);
                }
            }
        }}
    />;
}

SwitchGui.defaultProps = {
    description: '',
    uncheckedMessage: '',
    checkedMessage: '',
    defaultChecked: false
};

