import * as React from 'react';
import DataPairGui from '../../../../gui-set/DataPair';

/* Material UI */
import {
    Box,
    Button,
    FormControl,
    TextField,
    Typography
} from '@mui/material';

/* MUI Date and Time Pickers */
import {
    DatePicker,
    TimePicker
} from '@mui/x-date-pickers';

import dayjs from 'dayjs';

/* Material UI Icons */
import PhotoIcon from '@mui/icons-material/Photo';

function GeneralDetails(props) {
    return <>
        <DataPairGui
            title="Tanggal"
            leftWidth="100px"
            data={<>
                <Box>{props.selectedPlacemark.time.date}</Box>
                <Box>{props.selectedPlacemark.time.hour}</Box>
            </>}
        />
        <DataPairGui
            title="Alamat"
            data="Alamat dari Google Maps"
            leftWidth="100px"
        />
        <DataPairGui
            title="Koordinat"
            leftWidth="100px"
            data={
                props.selectedPlacemark.coor.lat + ', ' +
                props.selectedPlacemark.coor.lng
            }
        />
        <DataPairGui
            title="Catatan"
            leftWidth="100px"
            data={
                <Typography
                    variant='body1'
                    sx={{
                        overflowWrap: 'break-word',
                        whiteSpace: 'pre-line'
                    }}
                >
                    {props.selectedPlacemark.note}
                </Typography>
            }
            defaultData={
                <Typography
                    variant='body1'
                    sx={{
                        fontStyle: 'italic',
                        color: 'gray'
                    }}
                >
                    {'<Kosong>'}
                </Typography>
            }
        />
    </>;
}

/**
 * @param {Object} props 
 * @param {Function} props.noteSetPadding
 * @param {Object} props.generalInputValue
 * @param {Function} props.increaseInputChanged
 * @returns {Object} React Component
 */
function GeneralForm(props) {

    const datePicker_DOM = React.useRef(null),
          timePicker_DOM = React.useRef(null);

    React.useEffect(() => {
        if (props.generalInputValue) {
            props.generalInputValue.time.date = datePicker_DOM.current.childNodes[1].childNodes[0].value;
            props.generalInputValue.time.hour = timePicker_DOM.current.childNodes[1].childNodes[0].value;
        }
    });

    return <>
        <TextField
            label="Nama Tanda Letak"
            variant="outlined"
            size="small"
            onChange={(ev) => {
                props.generalInputValue.name = ev.target.value;
                props.increaseInputChanged();
            }}
        />
        <TextField
            label="Alamat"
            variant="outlined"
            size="small"
        />
        <FormControl sx={{ flexDirection: 'row' }}>
            <TextField
                label="Latitude"
                variant="outlined"
                size="small"
                sx={{ width: 1/2 }}
            />
            <TextField
                label="Longitude"
                variant="outlined"
                size="small"
                sx={{ width: 1/2 }}
            />
        </FormControl>
        <FormControl sx={{ flexDirection: 'row' }}>
            {/* The default date and time actually must be set from server */}
            <DatePicker
                label="Tanggal"
                slotProps={{ textField: { size: 'small' } }}
                sx={{ width: 1/2 }}
                defaultValue={ dayjs(new Date()) }
                ref={datePicker_DOM}
            />
            <TimePicker
                label="Pukul"
                slotProps={{ textField: { size: 'small' } }}
                sx={{ width: 1/2 }}
                defaultValue={new Date()}
                ref={timePicker_DOM}
            />
        </FormControl>
        <Button
            variant="outlined"
            sx={{ height: '60px' }}
        >
            <FormControl sx={{ flexDirection: 'row', gap: '8px' }}>
                <PhotoIcon/>
                <Box>Unggah Foto/Video</Box>
            </FormControl>
        </Button>
        <TextField
            label="Catatan"
            variant="outlined"
            size="small"
            multiline
            onClick={props.noteSetPadding}
            onKeyUp={props.noteSetPadding}
            onChange={(ev) => {
                props.generalInputValue.note = ev.target.value;
                props.increaseInputChanged();
            }}
        />
    </>;
}

class GeneralInputValue {
    constructor() {
        this.name = 'Tanpa Nama'
        this.time = { date: '', hour: '' };
        this.note = '';
    }
}

export {
    GeneralDetails,
    GeneralForm,
    GeneralInputValue
};

