import * as React from 'react';
import Placemark from '../../utilities/placemark';
import RerenderList from '../../utilities/status/rerender-list';
import * as Measurement from '../map/measurement';
import MainCard from './cards/main-card';

/**
 *  ARRANGEMENT:
 *   --POP
 *   ----CABLE
 *   ----COIL
 *   ----POLE
 *   ----CLOSURE
 *   ----ODP
 *   ----HANDHOLE
 *   ----CLIENT
 *   --ODC
 */

/**
 * Does not require 'react.useEffect'.
 * It has been done in 'List'.
 * 
 * @param {object} props 
 * @param {string} props.mainTitle 
 * @param {object} props.mainIcon 
 * @param {object} props.placemarksDataState 
 * @param {Function} props.setSelectedPlacemark 
 * @param {object} props.cardsOpenStatus 
 * @param {object} props.checkBoxStatus 
 * @param {object} props.selectedCardColorStatus 
 * @param {RerenderList} props.upwardCardRerenderList 
 * @returns {object} React Component 
 */
export default function Result(props) {

    /** Card Re-render */
    const cardRerenderList = new RerenderList(
        React.useState(false),
        props.upwardCardRerenderList
    );

    /**
     * Find and sort the placemark containers
     * from closest to the zero point.
     */

    let originPoint = {lat: 0, lng: 0},
        isNoContainer = false;

    const placemarksData = props.placemarksDataState.ref.current.data;

    const aRowPlacemarks = {
        containers: [],  // 1D Array (POPs)
        children: [],    // 2D Array (Other Placemarks)
        distances: []    // 2D Array (Numbers)
    };

    for (let i = 0; i < placemarksData.length; i++) {

        let shortestHypo = -1,
            closestPOP = null;

        for (const plc of placemarksData) {
            if (plc) {
                let isSkip = false;

                for (const pop of aRowPlacemarks.containers) {
                    if (pop === plc) {
                        isSkip = true;
                        break;
                    }
                }

                if (isSkip) continue;

                // the 'pop' and 'odc' are the same here
                if (plc.type === 'pop' || plc.type === 'odc') {
                    const testHypo = Measurement.getDistance(plc.coor, originPoint);

                    if (testHypo < shortestHypo || shortestHypo === -1) {
                        shortestHypo = testHypo;
                        closestPOP = plc;
                    }
                }
            }
        }

        if (closestPOP) {
            aRowPlacemarks.containers.push(closestPOP);
            aRowPlacemarks.children.push([]);
            aRowPlacemarks.distances.push([]);
            originPoint = closestPOP.coor;
        }
    }

    /*
    *   Create temporary container when
    *   POP or ODC not found (usually on document upload)
    */

    if (aRowPlacemarks.containers.length === 0) {
        isNoContainer = true;
        aRowPlacemarks.containers.push( new Placemark() );
        aRowPlacemarks.children.push([]);
        aRowPlacemarks.distances.push([]);
    }

    /* Divide other placemarks to closest POPs (containers) */

    for (let i = 0; i < aRowPlacemarks.containers.length; i++) {
        for (const plc of placemarksData) {

            if (!plc.client.isPseudo &&
                plc.type !== 'pop' &&
                plc.type !== 'odc'
            ) {
                let isPush = true;

                const testHypo = Measurement.getDistance(
                    plc.coor, aRowPlacemarks.containers[i].coor
                );

                for (let j = 0; j < aRowPlacemarks.containers.length; j++) {
                    let isBreak = false;

                    for (let k = 0; k < aRowPlacemarks.children[j].length; k++) {

                        if (aRowPlacemarks.children[j][k] === plc) {
                            isBreak = true;

                            if (aRowPlacemarks.distances[j][k] > testHypo) {

                                const switchingArr = [
                                    aRowPlacemarks.children,
                                    aRowPlacemarks.distances
                                ];

                                for (const arr of switchingArr) {
                                    arr[j] = arr[j].slice(0, k).concat(arr[j].slice(k + 1));
                                }

                                break;
                            }
                            else isPush = false;
                        }

                        if (isBreak) break;
                    }

                    if (isBreak) break;
                }

                if (isPush) {
                    aRowPlacemarks.children[i].push(plc);
                    aRowPlacemarks.distances[i].push(testHypo);
                }
            }
        }
    }

    /* Classify 'aRowPlacemarks' children */

    for (let i = 0; i < aRowPlacemarks.children.length; i++) {
        const classification = {};

        for (const plc of aRowPlacemarks.children[i]) {
            if (!classification[plc.type]) {
                classification[plc.type] = [plc];
            }
            else classification[plc.type].push(plc);
        }

        aRowPlacemarks.children[i] = classification;
    }

    /** Main Card */
    return <MainCard
        mainTitle={props.mainTitle}
        mainIcon={props.mainIcon}
        aRowPlacemarks={aRowPlacemarks}
        setSelectedPlacemark={props.setSelectedPlacemark}
        isNoContainer={isNoContainer}
        cardsOpenStatus={props.cardsOpenStatus}
        checkBoxStatus={props.checkBoxStatus}
        selectedCardColorStatus={props.selectedCardColorStatus}
        placemarksDataState={props.placemarksDataState}
        upwardCardRerenderList={cardRerenderList}
    />;
}

