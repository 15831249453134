import ButtonGui from '../../gui-set/Button';
import * as CenterFlex from '../../utilities/center-flex';

/* Material UI */
import { Box, TextField } from '@mui/material';

/* MUI Icons */
import SearchIcon from '@mui/icons-material/Search';

export default function SearchBox() {
    return <Box sx={CenterFlex.combine({
        width: '100%',
        borderRadius: '6px',
        backgroundColor: 'white'
    })}>
        <TextField
            label="Cari"
            size="small"
            variant="standard"
            sx={{ width: '80%', fontSize: '8px' }}
            InputProps={{ sx: { fontSize: '14px' }}}
        />
        <ButtonGui
            icon={<SearchIcon sx={{ scale: '0.8' }}/>}
            diameter={30}
            variant="contained"
        />
    </Box>;
}

