import * as React from 'react';
import { GeneralForm, GeneralInputValue } from './types/General';

/* Components */
import { CableForm } from './types/Cable';
import { ClientForm } from './types/Client';
import { ClosureForm } from './types/Closure';
import { OdcForm } from './types/ODC';
import { OdpForm } from './types/ODP';
import { PoleForm } from './types/Pole';
import { PopForm } from './types/POP';

/* Material UI */
import {
    Box
} from '@mui/material';

/**
 * props: {
 *   type (string)
 *   versatileDomRef (useRef)
 *   inputValue (object {special, general})
 *   increaseInputChanged (callback)
 * }
 */
export default function Form(props) {

    const [domHeight, setDomHeight] = React.useState(0);
    const container_DOM = React.useRef(null);

    const noteSetPadding_fun = () => {
        if (container_DOM && props.versatileDomRef &&
            container_DOM.current && props.versatileDomRef.current
        ) {
            if (domHeight <= props.versatileDomRef.current.clientHeight &&
                container_DOM.current.clientHeight > props.versatileDomRef.current.clientHeight
            ) {
                container_DOM.current.style.padding = '20px 0 20px 0';
                setDomHeight(container_DOM.current.clientHeight - 40);
            }
            else if (
                domHeight > props.versatileDomRef.current.clientHeight &&
                container_DOM.current.clientHeight - 40 <= props.versatileDomRef.current.clientHeight
            ) {
                container_DOM.current.style.padding = '0';
                setDomHeight(container_DOM.current.clientHeight);
            }
        }
    };

    React.useEffect(() => {
        noteSetPadding_fun();
        props.inputValue.general = new GeneralInputValue();
    }, []);

    //________|
    // STYLES |
    //________|

    let flexDisplaySx = {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        gap: '20px'
    };

    let formSx = Object.assign(
        {...flexDisplaySx},
        { backgroundColor: 'white' }
    );

    const formTypeSx = Object.assign(
        {...formSx},
        { padding: '10px 20px 10px 20px' }
    );

    formSx = Object.assign(formSx, {minHeight: '100%'});

    const formTypeSx_box = {
        width: 1,
        borderBottom: '1px solid #0060ff',
        fontSize: '12px',
        color: '#0060ff',
        marginBottom: '10px'
    };

    //____________|
    // COMPONENTS |
    //____________|

    let UsedSpecial;

    // 'props.type' expected in lowercase
    switch (props.type) {
        case 'cable': {
            UsedSpecial = <CableForm/>;
        break}
        case 'client': {
            UsedSpecial = <ClientForm/>;
        break}
        case 'closure': {
            UsedSpecial = <ClosureForm/>;
        break}
        case 'odc': {
            UsedSpecial = <OdcForm/>;
        break}
        case 'odp': {
            UsedSpecial = <OdpForm/>;
        break}
        case 'pole': {
            UsedSpecial = <PoleForm/>;
        break}
        case 'pop': {
            UsedSpecial = <PopForm/>;
        break}
        default: {
            UsedSpecial = <></>;
        }
    }

    return <Box
        ref={container_DOM}
        onClick={noteSetPadding_fun}
        sx={formSx}
    >
        {/* SPECIAL */}
        <Box sx={formTypeSx}>
            <Box sx={formTypeSx_box}>Spesial</Box>
            <Box sx={Object.assign({...flexDisplaySx}, { width: '300px' })}>
                {UsedSpecial}
            </Box>
        </Box>
        {/* GENERAL */}
        <Box sx={formTypeSx}>
            <Box sx={formTypeSx_box}>Umum</Box>
            <Box sx={Object.assign({...flexDisplaySx}, { width: '300px' })}>
                <GeneralForm
                    noteSetPadding={noteSetPadding_fun}
                    generalInputValue={props.inputValue.general}
                    increaseInputChanged={props.increaseInputChanged}
                />
            </Box>
        </Box>
    </Box>;
}

