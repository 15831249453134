/**
 * Useful for <ShadeShield/> set position.
 * The object contains 'name' and 'ref'
 */

export default class DomSet {
    constructor() {
        this.refs = [];
    }

    /**
     * @param {string} name 
     * @param {object} ref 
     * @returns {void}
     */
    add(name, ref) {
        for (const dom of this.refs) {
            if (dom.name === name) {
                dom.ref = ref;
                return;
            }
        }
        this.refs.push({ name, ref });
    }

    /**
     * @param {string} name 
     * @returns {object} Ref
     */
    query(name) {
        for (const dom of this.refs) {
            if (dom.name === name) {
                return dom.ref;
            }
        }
        
        return null;
    }

    /**
     * 
     * @param {string[]} names 
     * @returns [object[]] Refs Array
     */
    multiQuery(names) {
        const retArr = [];

        for (const name of names) {
            const ref = this.query(name);
            if (ref) retArr.push(ref);
        }
        
        return retArr;
    }
};

