export default function GoogleMapsLibrary(callback) {

    /* NOTE:
    *   -'window' is just a javascript default object
    *   -'google' is only exist in 'useEffect' of a component
    *     that included inside '@googlemaps/react-wrapper <Wrapper/>'
    */

    window.google.maps.importLibrary('core')
        .then((gm_core) => {
        window.google.maps.importLibrary('maps')
        .then((gm_maps) => {
        window.google.maps.importLibrary('places')
        .then((gm_places) => {
        window.google.maps.importLibrary('geocoding')
        .then((gm_geocoding) => {
        window.google.maps.importLibrary('routes')
        .then((gm_routes) => {
        window.google.maps.importLibrary('marker')
        .then((gm_marker) => {
        window.google.maps.importLibrary('geometry')
        .then((gm_geometry) => {
        window.google.maps.importLibrary('elevation')
        .then((gm_elevation) => {
        window.google.maps.importLibrary('streetView')
        .then((gm_streetView) => {
        window.google.maps.importLibrary('journeySharing')
        .then((gm_journeySharing) => {
        window.google.maps.importLibrary('drawing')
        .then((gm_drawing) => {
        window.google.maps.importLibrary('visualization')
        .then((gm_visualization) => {

        //__________________|
        //  AVAILABLE STUFF |
        //__________________|

        callback({
            core: gm_core,
            maps: gm_maps,
            places: gm_places,
            geocoding: gm_geocoding,
            routes: gm_routes,
            marker: gm_marker,
            geometry: gm_geometry,
            elevation: gm_elevation,
            streetView: gm_streetView,
            journeySharing: gm_journeySharing,
            drawing: gm_drawing,
            visualization: gm_visualization
        });

    })})})})})})})})})})})});
}

