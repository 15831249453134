import * as React from 'react';
import ButtonGui from '../../gui-set/Button';
import FloatingBoard from '../../gui-set/FloatingBoard';
import IconButtonGui from '../../gui-set/IconButton';
import MenuGui from '../../gui-set/Menu';
import ShadeShield from '../../gui-set/ShadeShield';
import './Account.css';

/* Material UI Icons */
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import CloseButton from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';

/**
 * @param {object} props 
 * @param {object} props.domSet 
 * @param {object} props.floatingContainer 
 * @param {object} props.shadeShieldGlobalState 
 * @param {object} props.optionsFullyDisplayedState 
 * @returns {object} React Component
 */
export default function Account(props) {
    const container = React.useRef(null);
    
    React.useEffect(() => {
        props.domSet.current.add(
            'Account',
            container.current
        );
    }, []);

    const teammateNames = [
        'Blake',
        'Oliver',
        'Scott',
        'Thomas',
        'Owen',
        'Nick'
    ];

    /** Abbreviations */

    const isDisplayedFull = props.optionsFullyDisplayedState.value;

    const toggleOptionsFullyDisplayedState = () => {
        props.optionsFullyDisplayedState.setter( !isDisplayedFull );
    };

    /** COMPONENT */

    return <div className={isDisplayedFull ?
        'Options-Accounts-1' : 'Options-Accounts-1-Float'
    }>
        <div ref={container}
            className={isDisplayedFull ?
                'Options-Accounts-2' : 'Options-Accounts-2-Float'
            }
        >
            {isDisplayedFull ?
                <MenuGui
                    title="rekan"
                    icon={<AccountCircleOutlinedIcon/>}
                    width={120}
                    names={teammateNames}
                    usingDefaultItemIcons
                />
                : <></>
            }
            <div className={isDisplayedFull?
                'Options-MyAccount' : 'Options-MyAccount-Float'
            }>
                <div className={isDisplayedFull ?
                    'Options-MyAccount-Nickname' :
                    'Options-MyAccount-Nickname-Float'
                }>
                    Alex
                </div>
                <div className={isDisplayedFull?
                        'Options-MyAccount-Profile' :
                        'Options-MyAccount-Profile-Float'
                    }
                    onClick={() => {
                        props.floatingContainer.show(
                            <FloatingBoard sx={{
                                width: '300px',
                                height: '300px',
                                top: '10px',
                                right: '10px',
                                gap: '50px'
                            }}>
                                <h3>Halo, Alex</h3>
                                <ButtonGui
                                    variant="contained"
                                    name="Log Out"
                                    width="100px"
                                    height="30px"
                                />
                            </FloatingBoard>,
                            true
                        );
                    }}
                >
                    A
                </div>
                <div className={isDisplayedFull ?
                    'Options-MyAccount-Access' :
                    'Options-MyAccount-Access-Float'
                }>
                    Admin
                </div>
            </div>
            {props.shadeShieldGlobalState.value ?
                <ShadeShield
                    container={container.current}
                    leftComponents={props.domSet.current.multiQuery( ['List', 'Map'] )}
                    gaps={[0, 10, 10, 0]}
                    offsetCovered
                /> :
                <></>
            }
        </div>
        {isDisplayedFull ?
            <div style={{ width: '25px', height: '100%' }}>
                <IconButtonGui
                    diameter="20px"
                    icon={<CloseButton/>}
                    onClick={toggleOptionsFullyDisplayedState}
                />
            </div> :
            <IconButtonGui
                diameter="20px"
                icon={<MenuIcon sx={{ scale: '1.1' }}/>}
                onClick={toggleOptionsFullyDisplayedState}
            />
        }
    </div>;
}

