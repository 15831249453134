/* Generics */
import * as React from 'react';
import * as ValidateValue from '../utilities/validate-value';
import ShadeShield from '../gui-set/ShadeShield';
import './Map.css';

/* Google Maps */
import { Wrapper, Status } from '@googlemaps/react-wrapper';
import { MarkerClusterer } from '@googlemaps/markerclusterer';
import GoogleMapsLibrary from './map/google-maps';
import GraphicBundle from './map/graphic-bundle';
import * as GraphicMap from './map/graphic';

/* Material UI */
import {
    Box,
    CircularProgress,
    Typography
} from '@mui/material';

/* MUI Icon */
import ErrorIcon from '@mui/icons-material/Error';

/**
 * @param {object} props 
 * @param {object} props.domSet 
 * @param {object} props.drawState 
 * @param {object} props.shadeShieldGlobalState 
 * @param {object} props.mainDataState 
 * @param {Function} props.KMLDataState 
 * @param {Function} props.setSelectedPlacemark 
 * @param {object} props.selectedCardColorStatus 
 * @param {object} props.placemarksImportRef 
 * @param {object} props.importState 
 * @param {object} props.formInputValue 
 * @param {object} props.formInputValue.special 
 * @param {object} props.formInputValue.general 
 * @param {number} props.formInputChanged 
 * @returns {object} React Component
 */
function GoogleMaps(props) {
    const container = React.useRef(null);

    React.useEffect(() => {
        props.domSet.current.add(
            'Map',
            container.current
        );
    }, []);

    //________________|
    // GRAPHIC BUNDLE |
    //________________|

    const GB = React.useRef(new GraphicBundle());

    // remove vertex from polyline
    const removeVertexFromPolyline = React.useCallback(() => {
        for (let i = GB.current.vertexArr.length - 1;
            i >= 0; i--
        ) {
            GB.current.vertexArr[i].setMap(null);
            GB.current.vertexArr.pop();
        }
    }, []);

    // graphics update waiting for 'gmap' from map initialization
    const [isGmapInitialized, setIsGmapInitialized] = React.useState(false);

    const zoomDivision = {
        high: 11,
        medium: 12,
        low: 13
    };
    
    const zoomTypes = [
        ['pop', 'odc', 'client', 'cable', 'other'],
        ['odp', 'closure', 'hh', 'coil'],
        ['pole']
    ];
    
    const getZoomTier = React.useCallback((type_in) => {
        for (let i = 0; i < zoomTypes.length; i++) {
            for (const type of zoomTypes[i]) {
                if (type === type_in) {
                    return i;
                }
            }
        }
    }, []);

    const createGraphicsByCallback = React.useCallback((GM, isApproved) => {
        return GraphicMap.createGraphicsByCallback(
            GM, GB,
            props.setSelectedPlacemark,
            props.selectedCardColorStatus,
            getZoomTier,
            isApproved
        );
    }, []);

    const addNewGraphic = React.useCallback((GM, pathCoors) => {
        GraphicMap.addNewGraphic(
            GM, GB,
            props.drawState,
            props.mainDataState,
            props.setSelectedPlacemark,
            props.selectedCardColorStatus,
            pathCoors
        );
    }, []);

    const setMarkerClusterer = React.useCallback((plcs_in) => {
        if (plcs_in.length > 1000) {
            GB.current.markerClusterer.addMarkers(
                plcs_in
            );
        }
    }, []);

    //____________________|
    // MAP INITIALIZATION |
    //____________________|

    React.useEffect(() => {
        GoogleMapsLibrary((GM) => {

            // google maps on container DOM
            GB.current.gmap = new GM.maps.Map(
                container.current,
                {
                    mapTypeId: GM.maps.MapTypeId.HYBRID,
                    controlSize: 25,
                    fullscreenControl: true,
                    fullscreenControlOptions: {
                        position: GM.core.ControlPosition.RIGHT_BOTTOM
                    },
                    styles: [
                        {
                          featureType: 'poi.business',
                          stylers: [{ visibility: 'off' }]
                        },
                        {
                          featureType: 'transit',
                          elementType: 'labels.icon',
                          stylers: [{ visibility: 'off' }]
                        }
                    ]
                }
            );

            /* overlay board with label */
            GB.current.infoWindow = new GM.maps.InfoWindow({
                content: "",
                disableAutoPan: true
            });

            // set 'infoWindowZoom' to default (not displayed state)
            const setDefaultInfoWindowZoom = () => {
                GB.current.infoWindowZoom.tier = -1;
                GB.current.infoWindowZoom.openAnchor = null;
                GB.current.infoWindowZoom.plcId = '';
            };

            /* 'infoWindow' clicked close button */
            GM.core.event.addListener(GB.current.infoWindow, 'closeclick', () => {
                setDefaultInfoWindowZoom();
            });

            /**
             *   Divided placemarks visibility based on 'zoomTier'.
             *   They visible on certain zoom level.
             */
            GM.core.event.addListener(GB.current.gmap, 'zoom_changed', () => {

                const mapZoom = GB.current.gmap.getZoom();
                
                let zoomTier = GB.current.zoomTier,
                    visibilityTier = [];
    
                // zoom tier 1 -> 0 (zoom out)
                if (mapZoom < zoomDivision.medium && zoomTier === 1) {
                    GB.current.zoomTier = 0;
                    visibilityTier = [1, false];
                }
                // zoom tier 0 -> 1  (zoom in)
                else if (mapZoom >= zoomDivision.medium && zoomTier === 0) {
                    GB.current.zoomTier = 1;
                    visibilityTier = [1, true];
                }
                // zoom tier 1 -> 2 (zoom in)
                else if (mapZoom >= zoomDivision.low && zoomTier === 1) {
                    GB.current.zoomTier = 2;
                    visibilityTier = [2, true];
                }
                // zoom tier 2 -> 1 (zoom out)
                else if (mapZoom < zoomDivision.low && zoomTier === 2) {
                    GB.current.zoomTier = 1;
                    visibilityTier = [2, false];
                }
    
                for (const grp of GB.current.graphics) {
                    if (getZoomTier(grp.pk_plc.type) === visibilityTier[0] &&
                        (
                            GB.current.infoWindowZoom.plcId === '' ||
                            (GB.current.infoWindowZoom.plcId !== '' && 
                            grp.pk_plc._id !== GB.current.infoWindowZoom.plcId)
                        )
                    ) {
                        grp.setVisible(visibilityTier[1]);
                    }
                }
            });

            // free click on map
            GM.core.event.addListener(GB.current.gmap, 'click', () => {
                GB.current.infoWindow.close();
                setDefaultInfoWindowZoom();
            });

            // trigger to display existing graphics (from database)
            setIsGmapInitialized(true);

            // setup marker clusterer with empty markers
            GB.current.markerClusterer = new MarkerClusterer({
                markers: [],
                map: GB.current.gmap,
                algorithmOptions: { maxZoom: zoomDivision.low },
                renderer: {
                    render: (
                        { count, position },
                        stats
                    ) => {
                        // change color if this cluster has more markers than the mean cluster
                        const color = (
                            count > Math.max(10, stats.clusters.markers.mean) ? 
                            "#00ff00" : "#ffff00"
                        );

                        // create svg literal with fill color
                        const svg = (
                            `<svg
                                fill="${color}"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 240 240"
                                width="50"
                                height="50"
                            >
                                <circle cx="120" cy="120" opacity=".6" r="70" />
                                <circle cx="120" cy="120" opacity=".3" r="90" />
                                <circle cx="120" cy="120" opacity=".2" r="110" />
                                <text
                                    x="50%" y="50%"
                                    style="fill:#fff"
                                    text-anchor="middle"
                                    font-size="50"
                                    dominant-baseline="middle"
                                    font-family="roboto,arial,sans-serif"
                                >
                                    ${count}
                                </text>
                            </svg>`
                        );

                        const title = `Cluster of ${count} markers`;

                        // adjust zIndex to be above other markers
                        const zIndex = Number(GM.marker.Marker.MAX_ZINDEX) + count;

                        return new GM.marker.Marker({
                            position,
                            zIndex,
                            title,
                            icon: {
                                url: `data:image/svg+xml;base64,${btoa(svg)}`,
                                anchor: new GM.core.Point(25, 25),
                            },
                        });
                    }
                }
            });
        });
    }, []);

    /*
    *   Draw existing placemarks from database
    *   (Main Data Display)
    */
    React.useEffect(() => {
        GoogleMapsLibrary((GM) => {
            if (!GB.current.isAlreadyConcatenate) {

                /* Usually this entered only when placemarks removed */

                // clean previous graphics
                for (const graphic of GB.current.graphics) {
                    graphic.setMap(null);
                }

                // draw new or existing placemarks
                GB.current.graphics = ValidateValue.cleanArray(
                    props.mainDataState.ref.current.data.map(
                        createGraphicsByCallback(GM, true)
                    ).concat(
                        props.KMLDataState.ref.current.data.map(
                            createGraphicsByCallback(GM, true)
                        )
                    )
                );

                // clear marker clusterer
                GB.current.markerClusterer.clearMarkers();
            }
            else GB.current.isAlreadyConcatenate = false;
        });
    }, [
        isGmapInitialized,
        props.mainDataState.updated,
        props.KMLDataState.updated
    ]);

    // center map view on initialized of database placemarks
    React.useEffect(() => {
        GoogleMapsLibrary((GM) => {
            if (GB.current.graphics.length > 0) {
                GraphicMap.centerMap(
                    GM, GB,
                    GB.current.graphics.map(grp => grp.pk_plc)
                );
            }
        });
    }, [isGmapInitialized]);

    /*
    *   Update popped up 'infoWindow' content
    *   following changes in 'formInputValue'
    */
    React.useEffect(() => {
        if (isGmapInitialized && props.formInputValue.general) {
            GB.current.infoWindow.setContent(
                GraphicMap.getStyledInfoWindowContent(
                    props.formInputValue.general.name
                )
            );
        }
    }, [props.formInputChanged]);

    //____________________|
    // OPTIONS DRAW STATE |
    //____________________|

    React.useEffect(() => {
        GoogleMapsLibrary((GM) => {

            if (isGmapInitialized) {
                const newGraphics_ref = GB.current.newGraphics;

                //_____________|
                // DRAW STATES |
                //_____________|

                switch(props.drawState.taskName) {
                    case 'addition': {
                        addNewGraphic(GM);
                    break}
                    case 'cable-addition-1': {
                        /* First 'coil' */
                        addNewGraphic(GM);
                    break}
                    case 'cable-addition-2': {
                        /* Second 'coil' */
                        newGraphics_ref[newGraphics_ref.length - 1].setDraggable(false);
                        addNewGraphic(GM);
                    break}
                    case 'cable-addition-3': {

                        //___________________|
                        // AUTO DRAWING PATH |
                        //___________________|

                        const dirSer = new GM.routes.DirectionsService();

                        dirSer.route(
                            {
                                origin: newGraphics_ref[0].getPosition(),
                                destination: newGraphics_ref[1].getPosition(),
                                travelMode: 'WALKING'
                            }
                        ).then((result) => {
                            const overviewPolyline = result.routes[0].overview_polyline;

                            // 'LatLng' array
                            const pathCoors = GM.geometry.encoding.decodePath(overviewPolyline)
                                  .map((coor) => coor.toJSON());
                                
                            addNewGraphic(GM, pathCoors);
                        });

                        // set second coil not draggable
                        newGraphics_ref[newGraphics_ref.length - 1].setDraggable(false);
                    break}
                    case 'selection': {
                        /* NOT SPECIFIED */
                    break}
                    case 'save': {
                        if (newGraphics_ref.length > 0) {

                            for (const marker of newGraphics_ref) {

                                marker.setDraggable(false);
                                const placemarksData = props.mainDataState.ref.current.data;

                                /* 'marker' has custom property object named 'pk_plc' */
                                marker.pk_plc = placemarksData[placemarksData.length - 1];

                                /* Set data from form */
                                marker.pk_plc.name = props.formInputValue.general.name;
                                marker.pk_plc.time.date = props.formInputValue.general.time.date;
                                marker.pk_plc.time.hour = props.formInputValue.general.time.hour;
                                marker.pk_plc.note = props.formInputValue.general.note;

                                /* Save last 'placemarksData' to database */
                                GB.current.graphics.push(marker);
                                marker.pk_plc.client.approved = true;
                            }

                            while(newGraphics_ref.length > 0) {
                                newGraphics_ref.pop();
                            }
                        }

                        removeVertexFromPolyline();
                    break}
                    case 'cancel': {
                        if (newGraphics_ref.length > 0) {
                            
                            /* remove 'newGraphics' (they are temporary) */
                            for (const marker of newGraphics_ref) {
                                marker.setMap(null);
                                props.mainDataState.popper(1);
                            }

                            while(newGraphics_ref.length > 0) {
                                newGraphics_ref.pop();
                            }
                        }

                        removeVertexFromPolyline();
                    }
                }
            }
        });
    }, [props.drawState]);

    //________________|
    // KMZ/KML IMPORT |
    //________________|

    React.useEffect(() => {
        GoogleMapsLibrary((GM) => {
            if (props.importState.value === 'ready') {

                // draw temporary imported placemarks
                GB.current.newGraphics = ValidateValue.cleanArray(
                    props.placemarksImportRef.value.current.data.map(
                        createGraphicsByCallback(GM, false)
                    )
                );

                if (GB.current.newGraphics.length > 0) {
                    
                    GraphicMap.centerMap(
                        GM, GB,
                        GB.current.newGraphics.map(grp => grp.pk_plc)
                    );

                    setMarkerClusterer(GB.current.newGraphics);
                    GB.current.isAlreadyConcatenate = true;
                }
            }
            else {
                let isKeepSave = false,
                    isExpectedState = false;

                if (props.importState.value === 'keep') {

                    props.KMLDataState.setter(
                        props.placemarksImportRef.value.current.data,
                        props.placemarksImportRef.value.current.title
                    );

                    props.KMLDataState.update();
                    isKeepSave = true;
                }
                else if (props.importState.value === 'save') {

                    props.mainDataState.setter(
                        props.placemarksImportRef.value.current.data
                    );

                    props.mainDataState.update();
                    isKeepSave = true;
                }
                else if (props.importState.value === 'cancel') {
                    GB.current.markerClusterer.clearMarkers();
                    isExpectedState = true;
                }

                if (isKeepSave) {
                    isExpectedState = true;

                    GB.current.graphics = GB.current.graphics.concat(
                        GB.current.newGraphics
                    );
                }

                if (isExpectedState) {
                    for (const grp of GB.current.newGraphics) {
                        grp.setMap(null);
                    }

                    GB.current.newGraphics = [];
                    props.placemarksImportRef.setter('', []);
                    props.importState.setter('');
                }
            }
        });
    }, [props.importState.value]);

    return <div className="GoogleMaps" ref={container}>
        <MapShadeShield
            domSet={props.domSet}
            shadeShieldGlobalState={props.shadeShieldGlobalState}
            container={container.current}
        />
    </div>;
}

/**
 * @param {object} domSet 
 * @param {object} shadeShieldGlobalState 
 * @returns {Function}
 */
function MapRender( domSet, shadeShieldGlobalState ) {
    return (status) => {
        return <EmptyMap
            domSet={domSet}
            shadeShieldGlobalState={shadeShieldGlobalState}
            status={status}
        />
    };
}

/**
 * @param {object} props
 * @param {object} props.domSet 
 * @param {object} props.shadeShieldGlobalState 
 * @param {object} props.status 
 * @returns {object} React Component
 */
function EmptyMap(props) {
    const container = React.useRef(null);

    React.useEffect(() => {
        props.domSet.current.add(
            'Map',
            container.current
        );
    }, []);

    return <Box className="EmptyMap" ref={container}>
        {props.status === Status.LOADING ?
            <> <CircularProgress/>
            <MapShadeShield
                domSet={props.domSet}
                shadeShieldGlobalState={props.shadeShieldGlobalState}
                container={container.current}
            /> </> :
            (props.status === Status.FAILURE ?
                <> <ErrorIcon sx={{
                    transform: 'scale(3)',
                    opacity: 0.25
                }}/>
                <Typography
                    variant="h5"
                    align="center"
                    sx={{ opacity: 0.35 }}
                >
                    Terjadi kesalahan
                </Typography>
                <MapShadeShield
                    domSet={props.domSet}
                    shadeShieldGlobalState={props.shadeShieldGlobalState}
                    container={container.current}
                /> </>
                : <></>
            )
        }
    </Box>;
}

/**
 * @param {object} props 
 * @param {object} props.domSet 
 * @param {object} props.shadeShieldGlobalState 
 * @param {object} props.container 
 * @returns {object} React Component
 */
function MapShadeShield(props) {
    return (props.shadeShieldGlobalState.value === 'full' ?
        <ShadeShield
            container={props.container}
            leftComponents={props.domSet.current.query('List')}
        /> :
        <></>
    );
}

/**
 * @param {object} props 
 * @param {object} props.domSet 
 * @param {object} props.shadeShieldGlobalState 
 * @param {object} props.drawState 
 * @param {object} props.mainDataState 
 * @param {Function} props.KMLDataState 
 * @param {Function} props.setSelectedPlacemark 
 * @param {object} props.placemarksImportRef 
 * @param {object} props.importState 
 * @param {object} props.formInputValue 
 * @param {object} props.formInputValue.special 
 * @param {object} props.formInputValue.general 
 * @param {number} props.formInputChanged 
 * @returns {object} React Component
 */
export default function Map(props) {

    const waitingContainer = React.useRef(null);

    React.useEffect(() => {
        props.domSet.current.add(
            'Map',
            waitingContainer.current
        );
    }, []);

    return <Wrapper
        apiKey={"AIzaSyC-O83t18KpAXw86HOqx1b-fan6We2lYEI"}
        render={MapRender(
            props.domSet,
            props.shadeShieldGlobalState
        )}
    >
        {props.mainDataState.fetchStatus === 'waiting' ?
            <>
                <Box className="EmptyMap" ref={waitingContainer}>
                    <CircularProgress/>
                    <MapShadeShield
                        domSet={props.domSet}
                        shadeShieldGlobalState={props.shadeShieldGlobalState}
                        container={waitingContainer.current}
                    />
                </Box>
            </>
            :
            /* status 'stop' */
            <GoogleMaps
                domSet={props.domSet}
                drawState={props.drawState}
                shadeShieldGlobalState={props.shadeShieldGlobalState}
                mainDataState={props.mainDataState}
                KMLDataState={props.KMLDataState}
                setSelectedPlacemark={props.setSelectedPlacemark}
                placemarksImportRef={props.placemarksImportRef}
                importState={props.importState}
                formInputValue={props.formInputValue}
                formInputChanged={props.formInputChanged}
            />
        }
    </Wrapper>;
}

