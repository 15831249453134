//______________________________________|
// DARK SHADE FOR UNAVAILABLE COMPONENT |
//______________________________________|

/* Material UI */
import { Box } from '@mui/material';

/**
 * @param {object} props 
 * @param {object} props.container 
 * @param {object[]} props.topComponents 
 * @param {object[]} props.leftComponents 
 * @param {number[]} props.gaps 
 * @param {boolean} props.offsetCovered 
 * @returns {object} React Component
 */
export default function ShadeShield(props) {
    let topMargin = 0, leftMargin = 0;

    /**
     *  The gaps are from container's container padding or flex gap.
     *  Sequentially from top[0], right[1], bottom[2] and left[3]
     * 
     *  If 'offsetCovered' enabled, the near gaps
     *  must be leaning to 'right' and 'bottom'.
     * 
     *  For instance: [0, 10, 10, 0] instead of [5, 5, 5, 5]
     * 
     *  But the gaps across will still need to be specified
     *  (not included to leaning gaps)
     */

    if (Array.isArray(props.gaps) &&
        props.gaps.length === 4
    ) {
        topMargin = props.gaps[0];
        leftMargin = props.gaps[3];
    }

    /* Top Array */
    if (Array.isArray(props.topComponents)) {
        for (const comp of props.topComponents) {
            topMargin += comp.clientHeight;
        }
    }
    /* Top Single */
    else if (
        props.topComponents &&
        typeof props.topComponents === 'object'
    ) {
        topMargin += props.topComponents.clientHeight;
    }

    /* Left Array */
    if (Array.isArray(props.leftComponents)) {
        for (const comp of props.leftComponents) {
            leftMargin += comp.clientWidth;
        }
    }
    /* Left Single */
    else if (
        props.leftComponents &&
        typeof props.leftComponents === 'object'
    ) {
        leftMargin += props.leftComponents.clientWidth;
    }

    let width = 0, height = 0;

    if (props.container) {
        
        width = props.container.clientWidth;
        height = props.container.clientHeight;

        if (props.offsetCovered &&
            Array.isArray(props.gaps) &&
            props.gaps.length === 4
        ) {
            width += props.gaps[1];
            height += props.gaps[2];
        }
    }

    return (
        <Box sx={{
            width: `${width}px`,
            height: `${height}px`,
            position: 'fixed',
            top: `${topMargin}px`,
            left: `${leftMargin}px`,
            background: '#00000044'
        }}/>
    );
}

ShadeShield.defaultProps = {
    gaps: [0, 0, 0, 0]
};

