//___________________________|
// CREATED BY 'bostonsinaga' |
//        2023/08/26         |
//___________________________|

const charSeeds = [
    '0', '1', '2', '3', '4', '5', '6', '7', '8', '9',
    'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J',
    'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T',
    'U', 'V', 'W', 'X', 'Y', 'Z',
    'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j',
    'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't',
    'u', 'v', 'w', 'x', 'y', 'z'
];

/**
 * @param {string[]} containerArray 
 * @param {number} count 
 * @param {number} minimumDigits 
 * @returns {void}
 */
function randomize(
    containerArray,
    count,
    minimumDigits = 3
) {
    if (count <= 0) {
        return;
    }

    while (containerArray.length > 0) {
        containerArray.pop();
    }

    if (minimumDigits <= 0) {
        minimumDigits = 1;
    }

    let possibleNumberArrangements = Math.pow(charSeeds.length, minimumDigits);

    for (let i = 0; i < count; i++) {
        let id = '';

        for (let j = 0; j < minimumDigits; j++) {
            id += charSeeds[Math.floor(Date.now() * Math.random() % charSeeds.length)];
        }

        let isRepeat = false;

        for (const registered_id of containerArray) {
            if (id === registered_id) {

                if (containerArray.length >= possibleNumberArrangements) {
                    minimumDigits++;
                }

                i--;
                isRepeat = true
                break;
            }
        }

        if (!isRepeat) {
            containerArray.push(id);
        }
    }
}

/**
 * @param {number} minimumDigits 
 * @param {string[]} existGroup - keep from duplication
 * @returns {string}
 */
function getSingle(minimumDigits = 3, existGroup = []) {
    const testArr = [];

    while(true) {
        let isDuplicate = false;
        randomize(testArr, 1, minimumDigits);
        
        for (const id of existGroup) {
            if (testArr[0] === id) {
                isDuplicate = true;
                break;
            }
        }

        if (!isDuplicate) break;
    }

    return testArr[0];
}

/**
 * @param {number} count 
 * @param {number} minimumDigits 
 * @returns {string[]}
 */
function getGroup(count, minimumDigits = 3) {
    
    if (count <= 0) {
        return [];
    }

    const retArr = [];
    randomize(retArr, count, minimumDigits);
    return retArr;
}

export {
    randomize,
    getSingle,
    getGroup
};

