/**
 * @param {number} num 
 * @returns {boolean}
 */
function isNumber(num) {
    if (typeof num === 'number' &&
        (num < 0 || num >= 0)
    ) { return true; }
    return false;
}

/**
 * @param {string} str 
 * @param {boolean} isMinimumEmpty 
 * @returns {boolean}
 */
function isString(str, isMinimumEmpty = false) {
    if (typeof str === 'string') {
        if (!isMinimumEmpty && str === '') return false;
        return true;
    }
    return false;
}

/**
 * @param {object} obj 
 * @param {boolean} isMinimumEmpty 
 * @returns {boolean}
 */
function isObject(obj, isMinimumEmpty = false) {
    if (typeof obj === 'object' &&
        !Array.isArray(obj) &&
        obj !== null
    ) {
        if (!isMinimumEmpty &&
            Object.keys(obj).length === 0
        ) return false;
        return true;
    }
    return false;
}

/**
 * @param {*[]} arr 
 * @param {boolean} isMinimumEmpty 
 * @returns {boolean}
 */
function isArray(arr, isMinimumEmpty = false) {
    if (Array.isArray(arr)) {
        if (!isMinimumEmpty && arr.length === 0) return false;
        return true;
    }
    return false;
}

/**
 * Either the values or references are same.
 * 
 * @param {*[]} arr1 
 * @param {*[]} arr2 
 * @returns {boolean}
 */
function is2ArrayEqual(arr1, arr2) {
    if (arr1 === arr2) {
        return true;
    }

    if (!Array.isArray(arr1) ||
        !Array.isArray(arr2) ||
        arr1.length !== arr2.length
    ) {
        return false;
    }

    for (let i = 0; i < arr1.length; i++) {
        if (arr1[i] !== arr2[i]) {
            return false;
        }
    }

    return true;
}

/**
 * Parameters are interchangeable.
 * Search array (the shorter) will compared
 * sequentially.
 * 
 * @param {*[]} arr1 - return -1 if 'arr1' is the most,
 * @param {*[]} arr2 - return 1 if 'arr2' is the most
 * @param {number} maxDigit - limit the indexes to be compared
 * @param {boolean} isReverseComparison - from back to front of the array
 * @returns {number} could be -1, 0, or 1
 */
function isArrayContains(arr1, arr2, maxDigit, isReverseComparison) {

    if (!arr1.length || !arr2.length) return 0;

    let arrContainer = arr1,
        arrSearch = arr2,
        equalCtr = 0;

    if (arr2.length > arr1.length) {
        arrContainer = arr2;
        arrSearch = arr1;
    }

    if (maxDigit) {
        if (arrContainer.length > maxDigit) {
            arrContainer = arrContainer.slice(0, maxDigit);
        }

        if (arrSearch.length > maxDigit) {
            arrSearch = arrSearch.slice(0, maxDigit);
        }
    }

    const whoHasTheMost = () => {
        return arr1.length < arr2.length ? -1 : 1;
    };

    let passIndex = 0, absCtr = 0;

    if (isReverseComparison) {
        let arrLengthDifference = arrContainer.length - arrSearch.length;
        
        for (let i = arrContainer.length - 1; i >= 0; i--) {
            let j = i - arrLengthDifference + passIndex;

            if (arrContainer[i] === arrSearch[j]) {
                equalCtr++;
            }
            
            if (equalCtr === arrSearch.length) {
                return whoHasTheMost();
            }

            if (absCtr === arrSearch.length - 1 && i > 0) {
                passIndex++;
                i = arrContainer.length - 1 - passIndex + 1;
                absCtr = 0;
                equalCtr = 0;
                continue;
            }
            
            absCtr++;
        }
    }
    else {
        for (let i = 0; i < arrContainer.length; i++) {
            let j = i - passIndex;

            if (arrContainer[i] === arrSearch[j]) {
                equalCtr++;
            }
            
            if (equalCtr === arrSearch.length) {
                return whoHasTheMost();
            }

            if (absCtr === arrSearch.length - 1 &&
                i < arrContainer.length - 1
            ) {
                passIndex++;
                i = passIndex - 1;
                absCtr = 0;
                equalCtr = 0;
                continue;
            }
            
            absCtr++;
        }
    }
    
    return 0;
}

/**
 * Cut an array at given index.
 * This will returns new array.
 * 
 * @param {*[]} arr_in 
 * @param {number} index 
 * @returns {*[]}
 */
function cutArray(arr_in, index) {

    if (!isArray(arr_in) || arr_in.length === 0) {
        return [];
    }

    if (arr_in.length === 1) {
        return arr_in.slice(0, index);
    }
    else return arr_in.slice(0, index).concat(
        arr_in.slice(index + 1)
    );
}

/**
 * Clean array from specific emptiness value of a type.
 * Eg. undefined, null, NaN.
 * 
 * @param {object} arr 
 * @param {string} fromEmptyType 
 * @returns {*[]}
 */
function cleanArray(arr, fromEmptyType = '') {
    let emptinessValue;
    
    switch (fromEmptyType) {
        case 'object' : {
            emptinessValue = null;
        break}
        case 'number' : {
            emptinessValue = NaN;
        break}
        case 'string' : {
            emptinessValue = '';
        break}
        case 'boolean' : {
            emptinessValue = false;
        break}
        default : {
            emptinessValue = undefined;
        }
    }

    if (Array.isArray(arr)) {
        for (let i = 0; i < arr.length; i++) {
            if (arr[i] === emptinessValue ||
                (typeof emptinessValue === 'array' && arr[i].length === 0) ||
                (typeof emptinessValue === 'number' && !isNumber(arr[i]))
            ) {
                arr = cutArray(arr, i);
                i--;
            }
        }
    }

    return arr;
}

export {
    isNumber,
    isString,
    isObject,
    isArray,
    is2ArrayEqual,
    isArrayContains,
    cutArray,
    cleanArray
};

