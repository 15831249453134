/**
 * This generally used in root component
 * for initialization to 'floatingComponent'.
 */
export default class FloatingContainer {
    #setShadeShieldGlobal;

    /**
     * Furthermore, 'floatingComponent' can be any React component.
     * It will be inside 'The Back Layer' (floating container).
     * 
     * @param {*[]} floatingState 
     * @param {Function} shadeShieldGlobalStateSetter 
     */
    constructor(floatingState, shadeShieldGlobalStateSetter) {

        if (!Array.isArray(floatingState) ||
            floatingState.length !== 2 ||
            typeof shadeShieldGlobalStateSetter !== 'function'
        ) {
            console.error("'FloatingContainer' constructor argument error.");
        }

        this.value = floatingState[0];
        this.setter = floatingState[1];
        this.#setShadeShieldGlobal = shadeShieldGlobalStateSetter;
        this.#initFadeOutCSSKeyframes();
    }

    /**
     * Initialization of fade out animation.
     */
    #initFadeOutCSSKeyframes() {
        const rules = `
            @keyframes floatingFadeOutAnimation {
                50% {opacity: 1;}
                100% {opacity: 0;}
            }
        `;

        const styleEl = document.createElement('style');
        document.head.appendChild(styleEl);

        const styleSheet = styleEl.sheet;
        styleSheet.insertRule(rules, 0);
    }

    /**
     * Pop-up 'floatingComponent'.
     * Click anywhere outside the 'floatingComponent' will disappear it.
     * 
     * @param {object} floatingComponent - react component
     * @param {boolean} isFullShadeShield - darken the back layer of 'floatingComponent'
     * @param {boolean} isFadeOut - fade out ('floatingComponent' disappears by itself)
     */
    show(
        floatingComponent,
        isFullShadeShield = false,
        isFadeOut = false
    ) {
        if (isFullShadeShield) {
            this.#setShadeShieldGlobal('full');
        }

        const removeFun = () => { this.remove(); };
        if (isFadeOut) setTimeout(removeFun, 2000);

        /**
         * The 'floatingComponent' is inside a div called 'The Back Layer'.
         * 'The Back Layer' (floating container) is a transparent background
         * that when clicked will disappear the 'floatingComponent'.
         */
        this.setter(<div
            style={Object.assign(
                {
                    position: 'absolute',
                    top: 0, right: 0,
                    bottom: 0, left: 0
                },
                isFadeOut? {
                    animationName: 'floatingFadeOutAnimation',
                    animationDuration: '2s'
                } : {}
            )}
            onClick={removeFun}
        >
            {floatingComponent}
        </div>);
    }

    /**
     * Remove the 'floatingComponent' and 'The Back Layer'.
     * Generally called in 'The Back Layer' click event
     * or at end of 'floatingComponent' fade out animation.
     */
    remove() {
        this.#setShadeShieldGlobal('');
        this.setter(null);
    }
};

