import * as React from 'react';
import { generateRealClassyCards } from './real-classy-cards';
import CardGui from '../../../gui-set/Card';
import { getOrderFlag } from '../../../gui-set/Card';
import * as PlacemarkType from '../../../utilities/placemark-type';
import RerenderList from '../../../utilities/status/rerender-list';

/**
 * @param {object} props 
 * @param {object} props.containerPlacemark 
 * @param {object} props.aRowPlacemarks 
 * @param {Function} props.setSelectedPlacemark 
 * @param {object} props.dummyClassyCards 
 * @param {number[]} props.depth 
 * @param {object} props.cardsOpenStatus 
 * @param {object} props.checkBoxStatus 
 * @param {object} prop.selectedCardColorStatus 
 * @param {RerenderList} props.upwardCardRerenderList 
 * @returns {object} React Component 
 */
export default function ContainerCard(props) {

    /** Card Re-render */
    const cardRerenderList = new RerenderList(
        React.useState(false),
        props.upwardCardRerenderList
    );

    const realClassyCards = generateRealClassyCards(
        props.dummyClassyCards,
        props.setSelectedPlacemark,
        props.containerPlacemark,
        props.depth,
        props.cardsOpenStatus,
        props.checkBoxStatus,
        props.selectedCardColorStatus,
        cardRerenderList
    );

    return <CardGui
        name={props.containerPlacemark.name}
        iconUrl={PlacemarkType.getIconUrl( props.containerPlacemark.type )}
        order={getOrderFlag(
            props.depth[props.depth.length - 1],
            props.aRowPlacemarks.containers.length
        )}
        indent={1}
        depth={props.depth}
        openStatus={props.cardsOpenStatus}
        checkBoxStatus={props.checkBoxStatus}
        selectedColorStatus={props.selectedCardColorStatus}
        placemark={props.containerPlacemark}
        anchor={() => { props.setSelectedPlacemark(props.containerPlacemark); }}
        rerenderList={cardRerenderList}
        children={realClassyCards}
    />;
}

