import * as ValidateValue from "../validate-value";
import Depths from './depths';

/**
 *  A collection of flags (generally boolean)
 *  referring to depths (indexes array).
 *  This a parent-children relationship.
 * 
 *  For example, the parent flag can be set as 'true'
 *  and the children will either follow it or not.
 */
export default class ParentChildStatus {
    #defaultValue;

    /**
     * @param {Depths} depths - React ref of indexes array (from 'Depths.js')
     */
    constructor(depths) {
        this.flags = [];
        this.depths = depths;

        // used when 'applyToDescendant' is true
        this.activeCount = 0;
        this.previouslyOneAboveFullActive = false;
    }

    /**
     * Initialization. The 'depths' expected
     * to not empty and updated when re-init.
     * The 'i' is initialized to a length of array
     * because the 'flags' need to keep the previous
     * init when re-init.
     * 
     * @param {*} defaultValue 
     */
    init(defaultValue) {
        this.#defaultValue = defaultValue;
        for (let i = this.flags.length; i < this.depths.current.length(); i++) {
            this.flags.push(defaultValue);
        }
    }

    /**
     * Set 'this.flags' of placemark (based on 'this.depths')
     * for itself or its descendant when its display's event triggered.
     * 
     * @param {number} flag_in 
     * @param {number[]} depth_in 
     * @param {boolean} applyToDescendant 
     * @param {*} intermediateFlag - Will use if 'applyToDescendant' is true
     */
    set(flag_in, depth_in, applyToDescendant = false, intermediateFlag = 1) {

        let parentIndexes = [],
            targetDepthReached = false,
            oneAboveParentChildren = {total: 0, active: 0};

        const lastParentIndexes = () => parentIndexes[parentIndexes.length - 1],
              isRootDepth = depth_in.length === 1 && depth_in[0] === 0;

        /** Iterate all 'this.depths' */

        for (let i = 0; i < this.depths.current.length(); i++) {

            const currentDepth = this.depths.current.get(i),
                  currentLength = this.depths.current.get(i).length;

            // multiple effect
            if (applyToDescendant) {
                // parent, self, or descendant of 'depth_in'
                if (ValidateValue.isArrayContains(
                    currentDepth,
                    depth_in,
                    depth_in.length,
                    true
                )) {
                    // parents
                    if (currentLength < depth_in.length) {
                        if (!targetDepthReached) parentIndexes.push(i);
                    }
                    // self or descendant
                    else {
                        // occurred once
                        if (currentLength === depth_in.length) {
                            targetDepthReached = true;
                            oneAboveParentChildren.total++;
                            if (flag_in) oneAboveParentChildren.active++;
                        }

                        if (flag_in && !this.flags[i]) {
                            this.activeCount++;
                        }
                        else if (
                            !flag_in && this.flags[i] &&
                            this.flags[i] !== intermediateFlag
                        ) {
                            this.activeCount--;
                        }

                        this.flags[i] = flag_in;
                    }
                }
                // neighbor of 'depth_in'
                else if (
                    currentLength === depth_in.length &&
                    ValidateValue.isArrayContains(
                        this.depths.current.get(lastParentIndexes()),
                        currentDepth,
                        currentLength,
                        true
                    ) &&
                    (targetDepthReached ||
                    this.depths.current.get(lastParentIndexes()).length
                    === currentLength - 1)
                ) {
                    oneAboveParentChildren.total++;
                    if (this.flags[i]) oneAboveParentChildren.active++;
                }
            }
            // single effect
            else if (
                !applyToDescendant &&
                ValidateValue.is2ArrayEqual(currentDepth, depth_in)
            ) {
                this.flags[i] = flag_in;
                return;
            }
        }

        if (applyToDescendant) {

            const isAllActive_oneAbove = (
                oneAboveParentChildren.active === oneAboveParentChildren.total
            );

            // one above parent switched
            if (isAllActive_oneAbove || oneAboveParentChildren.active === 0) {
                this.flags[lastParentIndexes()] = flag_in;

                if (isAllActive_oneAbove) {                    
                    this.activeCount++;
                    this.previouslyOneAboveFullActive = true;

                    /**
                     * Instant fix for a descendant when checked
                     * which should result in all being checked
                     * but root still in intermediate flag
                     * ('activeCount' exceeds limit)
                     */
                    if (this.activeCount > this.flags.length) {
                        this.activeCount = this.flags.length;
                    }
                }
                else if (this.activeCount > 0) {
                    this.activeCount--;
                }
            }
            // any of one above parent children are not selected
            else {
                this.flags[lastParentIndexes()] = intermediateFlag;

                if (this.previouslyOneAboveFullActive && isRootDepth) {
                    this.activeCount--;
                    this.previouslyOneAboveFullActive = false;
                }
            }

            /**
             * One above parent 'activeCount' addition
             * is not applicable in root depth interaction
             */

            // keep 'activecount' from negative
            if (this.activeCount < 0) this.activeCount = 0;

            for (let i = 0; i < parentIndexes.length - 1; i++) {
                // beyond parent switched
                if (this.activeCount === this.flags.length ||
                    this.activeCount === 0
                ) {
                    this.flags[parentIndexes[i]] = flag_in;
                }
                // any of beyond parent children are not selected
                else this.flags[parentIndexes[i]] = intermediateFlag;
            }
        }
    }

    /**
     * Remove depth and flag.
     * 
     * @param {number[]} depth_in 
     * @param {boolean} alsoChildren 
     */
    drop(depth_in, alsoChildren) {
        const depthIndex = this.depths.current.getIndex(depth_in);

        if (depthIndex !== -1) {
            this.depths.current.drop(depth_in, alsoChildren);
            this.flags = ValidateValue.cutArray(this.flags, depthIndex);
        }
    }

    /**
     * Get flag by depth.
     * 
     * @param {number[]} depth_in 
     * @param {*} defaultValue - not really necessary because it is already specified in initialization
     * @returns {*} following 'defaultValue'
     */
    get(depth_in, defaultValue) {
        for (let i = 0; i < this.depths.current.length(); i++) {
            if (ValidateValue.is2ArrayEqual(this.depths.current.get(i), depth_in)) {
                return this.flags[i];
            }
        }

        if (defaultValue === undefined) return this.#defaultValue;
        return defaultValue;
    }
};

