import * as React from 'react';
import './App.css';

/* PetaKabel Major Components */
import List from './components/List';
import Map from './components/Map';
import Options from './components/Options';

/* Components Stuff */
import * as DrawOptions from './components/map/draw-options';
import FloatingContainer from './gui-set/floating-container';
import DomSet from './utilities/dom-set';
import Placemark from './utilities/placemark';
import Depths from './utilities/status/depths';
import ParentChildStatus from './utilities/status/parent-child-status';
import CheckBoxStatus from './utilities/status/check-box-status';
import RerenderList from './utilities/status/rerender-list';

/* Material UI */
import { Box } from '@mui/material';

/* MUI Date and Time Pickers */
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/id';

export default function App() {

    /**
     *  'Map' action states set.
     *  See './components/map/draw-options.js' for available options.
     *  ['inactive', 'addition', 'selection']
     */
    const [drawState, setDrawState] = React.useState(DrawOptions.getInactive());

    /**
     *  This will toggle all components that use <ShadeShield/>
     *  into unavailable mode with dark shade.
     *  
     *  Available values:
     *  'full', 'crop', ''
     */
    const [shadeShieldGlobal, setShadeShieldGlobal] = React.useState('');

    const shadeShieldGlobalState = {
        value: shadeShieldGlobal,
        setter: setShadeShieldGlobal
    };

    /**
     * Useful for <ShadeShield/> set position.
     * The object contains 'name' and 'ref'.
     */
    const domSet = React.useRef(new DomSet());

    /** Pop-up component */
    
    const floatingComponentState = React.useState(null);

    const floatingContainerRef = React.useRef(
        new FloatingContainer(
            floatingComponentState,
            setShadeShieldGlobal
        )
    );

    /** Placemarks from user local file */

    const placemarksImport = React.useRef({
        title: '',
        data: []
    });

    const setPlacemarksImport = (title, data) => {
        placemarksImport.current.title = title;
        placemarksImport.current.data = data;
    };

    /**
     *  Available values:
     *    'ready'
     *    'keep'
     *    'save'
     *    'cancel'
     */
    const [importStatus, setImportStatus] = React.useState('');

    /**
     *  Placemarks from database.
     *  Snipping: [{ id, name, type, date, coordinate, ... }]
     */
    const [placemarksFetchStatus, setPlacemarksFetchStatus] = React.useState('waiting'),
          [mainDataUpdated, updateMainData] = React.useState(false),
          mainData = React.useRef({
            title: '',
            data: []
          });

    /**
     *  Bundled placemarks data.
     *  This combined with React 'useState' and 'useRef'.
     */
    const mainDataState = {
        fetchStatus: placemarksFetchStatus,
        ref: mainData,
        updated: mainDataUpdated,
        update: () => {
            updateMainData(!mainDataUpdated);
        },
        setter: (dataArr, title = null) => {
            mainData.current.data = dataArr;

            if (typeof title === 'string') {
                mainData.current.title = title;
            }
        },
        pusher: (plcArr) => {
            mainData.current.data = mainData.current.data.concat(plcArr);
        },
        popper: (count) => {
            if (count <= mainData.current.data.length) {
                mainData.current.data = mainData.current.data.slice(
                    0, mainData.current.data.length - count
                );
            }
        }
    };

    /**
     *  KML placemarks from upload.
     *  Snipping: [{ id, name, type, date, coordinate, ... }]
     */
    const [KMLDataUpdated, updateKMLData] = React.useState(false),
          KMLData = React.useRef({
            title: '',
            data: []
          });

    /**
     *  Bundled KML data.
     *  This combined with React 'useState' and 'useRef'.
     */
    const KMLDataState = {
        ref: KMLData,
        updated: KMLDataUpdated,
        update: () => {
            updateKMLData(!KMLDataUpdated);
        },
        setter: (dataArr, title = null) => {
            KMLData.current.data = dataArr;
            
            if (typeof title === 'string') {
                KMLData.current.title = title;
            }
        }
    };

    /**
     *  Will triggered from 'onClick' of Card or Marker
     *  and it contains 'Placemark' data.
     */
    const selectedPlacemarkState = React.useState(null);

    /**
     *   The 'special' and 'general' are assigned in 'Form'.
     *   The data (values) are assigned to placemarks in 'Map' after saved from 'Draw'.
     */
    const FormInputValue = React.useRef({
        special: null,
        general: null
    });

    /** The number will increase when changed */
    const [formInputChanged, setFormInputChanged] = React.useState(0);

    const increaseFormInputChanged = () => {
        if (formInputChanged < Number.MAX_VALUE) {
            setFormInputChanged(formInputChanged + 1);
        }
        else setFormInputChanged(0);
    };

    /**
     * Depths is array indexes that row and point
     * to a placemark index from its parent as a children.
     * For example:
     * 
     * [0] = Parent
     * [0, 1] = Descendant 1, Child 1
     * [0, 2] = Descendant 1, Child 2
     * and so on...
     */
    const depths = React.useRef(new Depths());

    /**
     * Prevent list cards to closed
     * when change occur in 'mainDataState'.
     */
    const cardsOpenStatus = React.useRef(new ParentChildStatus(depths));

    /**
     * Able to set checked to a card descendants check box.
     */
    const checkBoxStatus = React.useRef(new CheckBoxStatus(depths));

    /**
     * Keep a single card background color after it clicked.
     */
    const selectedCardColorStatus = React.useRef(new ParentChildStatus(depths));

    /**
     * Card rerender to show updated 'ParentChildStatus'.
     */
    const cardRerenderState = React.useState(false);

    /** Options's display status */
    const [optionsFullyDisplayed, setOptionsFullyDisplayed] = React.useState(false);

    //________________|
    // INITIALIZATION |
    //________________|

    React.useEffect(() => {

        /** Listen keyboard's pressed key */
        document.addEventListener('keyup', (ev) => {
            switch (ev.key) {
                case 'Escape': {
                    floatingContainerRef.current.remove();
                }
                // more keys...
            }
        });

        /** Get data from database */
        fetch('https://raw.githubusercontent.com/bostonsinaga/PK-ContentSamples/main/database/Placemarks.json')
            .then((res_cors) => res_cors.json())
            .then((json_data) => {
                mainDataState.setter(
                    json_data.map(rawPlc => new Placemark(null, rawPlc)),
                    'Main Data'
                );

                mainDataState.update();
                setPlacemarksFetchStatus('stop');
            })
            .catch(() => {
                alert("Kesalahan mengambil data");
                setPlacemarksFetchStatus('stop');
            })
    }, []);

    //____________|
    // COMPONENTS |
    //____________|

    return <LocalizationProvider
        dateAdapter={AdapterDayjs}
        adapterLocale="id"
    >
        <div className='App'>
            <List
                domSet={domSet}
                floatingContainer={floatingContainerRef.current}
                shadeShieldGlobalState={shadeShieldGlobalState}
                mainDataState={mainDataState}
                KMLDataState={KMLDataState}
                setSelectedPlacemark={selectedPlacemarkState[1]}
                cardsOpenStatus={cardsOpenStatus.current}
                checkBoxStatus={checkBoxStatus.current}
                selectedCardColorStatus={selectedCardColorStatus.current}
                upwardCardRerenderList={new RerenderList(cardRerenderState, null)}
            />
            <Map
                domSet={domSet}
                shadeShieldGlobalState={shadeShieldGlobalState}
                drawState={drawState}
                mainDataState={mainDataState}
                KMLDataState={KMLDataState}
                setSelectedPlacemark={selectedPlacemarkState[1]}
                selectedCardColorStatus={selectedCardColorStatus.current}
                placemarksImportRef={{
                    value: placemarksImport,
                    setter: setPlacemarksImport
                }}
                importState={{ value: importStatus, setter: setImportStatus }}
                formInputValue={FormInputValue.current}
                formInputChanged={formInputChanged}
            />
            <Options
                domSet={domSet}
                floatingContainer={floatingContainerRef.current}
                drawState={{ value: drawState, setter: setDrawState }}
                shadeShieldGlobalState={shadeShieldGlobalState}
                selectedPlacemarkState={selectedPlacemarkState}
                setPlacemarksImport={setPlacemarksImport}
                setImportStatus={setImportStatus}
                formInputValue={FormInputValue.current}
                increaseFormInputChanged={increaseFormInputChanged}
                fullyDisplayedState={{
                    value: optionsFullyDisplayed,
                    setter: setOptionsFullyDisplayed
                }}
            />
            {floatingComponentState[0] ?
                <Box
                    sx={{
                        position: 'absolute',
                        top: 0,
                        right: 0,
                        bottom: 0,
                        left: 0
                    }}
                    onClick={() => {
                        floatingContainerRef.current.remove();
                    }}
                >
                    {floatingComponentState[0]}
                </Box> :
                <></>
            }
        </div>
    </LocalizationProvider>;
}

