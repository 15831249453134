import * as React from 'react';
import RerenderList from '../utilities/status/rerender-list';
import ParentChildStatus from '../utilities/status/parent-child-status';
import CheckBoxStatus from '../utilities/status/check-box-status';
import ArrayMod from '../utilities/array-mod';
import * as IdGenerator from '../utilities/id-generator';
import * as CenterFlex from '../utilities/center-flex';
import * as ValidateValue from '../utilities/validate-value';

/* Material UI */
import {
    Box,
    IconButton,
    Typography
} from '@mui/material';

/* MUI Icons */
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';

// static like variables
const CardVars = {
    SELECTED_COLOR_CODE: '#6591d2',
    SELECTED_DEPTH: [],
    MINIMUM_WIDTH: new ArrayMod([0]),
    OPENED_ID: NaN,
    IS_ROOT_INIT: true,
    RECENTLY_OPENED: true
};

/**
 * @param {number} currentIndex 
 * @param {number} total 
 * @returns {string} or undefined
 */
export function getOrderFlag(currentIndex, total) {
    let orderFlag = undefined;

    if (total === 1) {
        orderFlag = 'body';
    }
    else if (currentIndex === 0) {
        orderFlag = 'head';
    }
    else if (currentIndex === total - 1) {
        orderFlag = 'tail';
    }

    return orderFlag;
}

/**
 * Giving a number to 'chainingUpwards' will set the number of times to chain.
 * Giving it to 'true' will continue chaining all the way to the root.
 * Giving it to less than '0' or 'false' will set individual rerender.
 * 
 * @param {RerenderList} rerenderList 
 * @param {boolean} chainingUpwards 
 */
function rerender(rerenderList, chainingUpwards) {
    rerenderList.current[1](!rerenderList.current[0]);

    if (chainingUpwards) {
        let chainedUpward = rerenderList.upward,
            limitedChainingCount = 0,
            limitedChainingCtr = 0;

        if (ValidateValue.isNumber(chainingUpwards) &&
            chainingUpwards > 0
        ) {
            limitedChainingCount = chainingUpwards;
        }
        else if (chainingUpwards !== true) return;

        // react 'useState' setter inverts the boolean
        while (chainedUpward) {
            chainedUpward.current[1](!chainedUpward.current[0]);
            chainedUpward = chainedUpward.upward;

            if (limitedChainingCount) {
                limitedChainingCtr++;
                if (limitedChainingCtr >= limitedChainingCount) break;
            }
        }
    }
}

/**
 * @param {object} props 
 * @param {string} props.name 
 * @param {string} props.iconUrl 
 * @param {object} props.iconComponent 
 * @param {string} props.order - e.g. 'head', 'body', 'tail' or undefined
 * @param {number} props.indent - started from 0
 * @param {number[]} props.depth 
 * @param {ParentChildStatus} props.openStatus 
 * @param {CheckBoxStatus} props.checkBoxStatus 
 * @param {ParentChildStatus} props.selectedColorStatus 
 * @param {boolean} props.innerVisibilityInit 
 * @param {object} props.placemark 
 * @param {Function} props.anchor 
 * @param {RerenderList} props.rerenderList 
 * @param {object[]} props.children 
 * @returns {object} React Component
 */
export default function CardGui(props) {

    /** CONSTANTS */

    const checkBoxIconSx = { width: '100%', height: '100%' },
          iconSize = 15,
          gapSize = 3,
          iconPx = '15px',
          gapPx = '3px',
          isRoot = props.depth.length === 1;

    const titleDOM = React.useRef(null),
          mainBoxDOM = React.useRef(null),
          selfRerender = React.useState(false);

    const rerenderSelf = (uptoRoot = false) => {
        setTimeout(() => {
            selfRerender[1](!selfRerender[0]);
            if (uptoRoot) rerender(props.rerenderList, true);
        }, 100);
    };

    // by default the root folder always open
    React.useEffect(() => {
        if (isRoot) CardVars.OPENED_ID = props.placemark.id;
    }, []);

    /**
     * Set 'CardVars.MINIMUM_WIDTH' to a wider one
     * when opened and undo when closed.
     */
    if (titleDOM.current) {

        let isAncestorOpenClose;
        const isSelfOpenClose = props.placemark.id === CardVars.OPENED_ID;

        // only occurs in first initialization or root
        if (CardVars.IS_ROOT_INIT) {
            CardVars.IS_ROOT_INIT = false;
            isAncestorOpenClose = true;
        }
        // normal assignment
        else {
            let plc = props.placemark;

            while (plc) {
                if (plc.id === CardVars.OPENED_ID) break;
                const plcPar = plc.client.parent;
                plc = plcPar;

                isAncestorOpenClose = (
                    ValidateValue.isObject(plcPar) &&
                    plcPar.id === CardVars.OPENED_ID
                );
            }
        }

        if (isSelfOpenClose || isAncestorOpenClose) {

            let gapPxTotal,
                interactionButtonsWidth,
                pipesWidth;

            // container placemark or folder
            if (props.placemark.hasChildren()) {
                gapPxTotal = 3 * gapSize;
                interactionButtonsWidth = iconSize * 2;
                pipesWidth = props.indent * 22;
            }
            // placemark
            else {
                gapPxTotal = 2 * gapSize;
                interactionButtonsWidth = iconSize;
                pipesWidth = (props.indent + 1) * 22;
            }

            const fromFragmentsWidth = (
                gapPxTotal + interactionButtonsWidth +
                pipesWidth + titleDOM.current.clientWidth
            );

            // parent opened
            if (isAncestorOpenClose && CardVars.RECENTLY_OPENED &&
                CardVars.MINIMUM_WIDTH.back() < fromFragmentsWidth
            ) {
                if (CardVars.MINIMUM_WIDTH.length() === 1 &&
                    CardVars.MINIMUM_WIDTH.back() === 0
                ) {
                    CardVars.MINIMUM_WIDTH.set(0, fromFragmentsWidth);
                }
                else CardVars.MINIMUM_WIDTH.push(fromFragmentsWidth);

                rerenderSelf(true);
            }
            // self closed
            else if (
                !CardVars.RECENTLY_OPENED && isSelfOpenClose &&
                CardVars.MINIMUM_WIDTH.recentlyAdded
            ) {
                if (CardVars.MINIMUM_WIDTH.length() > 1) CardVars.MINIMUM_WIDTH.pop();
                rerenderSelf(true);
            }
        }
    }
    // to get 'titleDOM' reference
    else rerenderSelf();

    // set the box width
    if (mainBoxDOM.current) {
        if (CardVars.MINIMUM_WIDTH.back() === 0) {
            mainBoxDOM.current.style.width = 'unset';
        }
        else mainBoxDOM.current.style.width = CardVars.MINIMUM_WIDTH.back() + 'px';
    }
    // to get 'mainBoxDOM' reference
    else rerenderSelf();

    /** BACKGROUND COLOR */

    const isSelected = props.selectedColorStatus.get(props.depth),
          backgroundColorCode = isSelected ? CardVars.SELECTED_COLOR_CODE : 'white';

    // called in click event
    const setBackgroundColorCode = () => {
        props.selectedColorStatus.set(false, [0], true);
        props.selectedColorStatus.set(true, props.depth);
        CardVars.SELECTED_DEPTH = props.depth;
        rerender(props.rerenderList, true);
    };

    /** INNER VISIBILITY */

    const isInnerVisInitialized = React.useRef(false),
          innerVisInit_exist = typeof props.innerVisibilityInit === 'boolean';

    const isInnerVisible = (
        !isInnerVisInitialized.current && innerVisInit_exist ?
        props.innerVisibilityInit : props.openStatus.get(props.depth)
    );

    // called in click event
    const switchInnerVisibility = () => {

        const inverse = !props.openStatus.get(props.depth);
        CardVars.OPENED_ID = props.placemark.id;
        CardVars.RECENTLY_OPENED = inverse;

        props.openStatus.set(
            !isInnerVisInitialized.current ?
            (innerVisInit_exist ? !props.innerVisibilityInit : inverse)
            : inverse,
            props.depth
        );

        // when the selected item is closed then its parent will be selected
        if (ValidateValue.isArrayContains(
                props.depth, CardVars.SELECTED_DEPTH
            ) === -1
        ) { setBackgroundColorCode(); }

        if (!isInnerVisInitialized.current) isInnerVisInitialized.current = true;
        rerender(props.rerenderList, false);
    };

    /** BOX CHECKED */

    const boxCheckedFlag = props.checkBoxStatus.get(props.depth);

    // called in click event
    const switchBoxChecked = () => {
        
        props.checkBoxStatus.set(
            props.checkBoxStatus.get(props.depth) === 0 ? 2 : 0,
            props.depth,
            props.placemark
        );

        rerender(props.rerenderList, true);
    };

    /**
     * Will be used to avoid using multiple pipe signs
     * at children who have tail-ordered parent.
     */
    props.placemark.client.cardOrder = props.order;

    /**
     * PIPE SIGNS
     * To make it easier to look card descendants.
     */

    let isAbsoluteTail = false,  // a parent after the root with 'tail' order
        isRelativeTail = false,  // any parent with 'tail' order
        ancestorBuffer = props.placemark.client.parent;

    if (ancestorBuffer) {
        isRelativeTail = ancestorBuffer.client.cardOrder === 'tail';
    }

    // determine whether the order is at the bottom or not
    while (ancestorBuffer) {
        const order = ancestorBuffer.client.cardOrder;

        if (order === 'tail') {
            isAbsoluteTail = true;
        }
        else if (order !== 'body') {
            isAbsoluteTail = false;
        }

        ancestorBuffer = ancestorBuffer.client.parent;
    }

    // the minimum value is 0
    let pipeCount = props.indent;

    const pipeIds = IdGenerator.getGroup(pipeCount),
          pipeSigns = [];

    // 'APFg' stands for 'additionalPipeFlag'
    const APFg_INIT = 0,
          APFg_LAST = 1,
          APFg_ADDED = 2;

    let pipeFileName = "",
        additionalPipeFlag = APFg_INIT;

    // shorten string
    const getPipeName = (type) => {

        // file name suffix as the selected item
        const pipeSelectedName = isSelected ? '-selected' : '';

        return `./GUI/pipes/${type}${pipeSelectedName}.png`;
    }

    // determine pipe shape
    for (let i = 0; i < pipeCount; i++) {

        // determine the right-end type
        if (i === pipeCount - 1) {

            if (additionalPipeFlag === APFg_INIT) {
                if (props.order === 'tail' || props.order === 'body') {
                    pipeFileName = getPipeName('downstream');
                }
                else pipeFileName = getPipeName('intersection');
            }
            else if (additionalPipeFlag === APFg_ADDED) {
                pipeFileName = getPipeName('branch');
            }

            if (additionalPipeFlag === APFg_INIT) {
                additionalPipeFlag = APFg_LAST;
            }
        }
        // determine the type 'blank' or 'canal'
        else {
            if ((isAbsoluteTail && i === 0) ||
                (isRelativeTail && !isAbsoluteTail && i !== 0)
            ) {
                pipeFileName = getPipeName('blank');
            }
            else if (
                (!isRelativeTail && (!isAbsoluteTail || (isAbsoluteTail && i > 0))) ||
                (isRelativeTail && !isAbsoluteTail && i < pipeCount - 1)
            ) {
                pipeFileName = getPipeName('canal');
            }
        }

        // HTML image
        if (pipeFileName !== "") {
            pipeSigns.push(<Box
                key={pipeIds[i]}
                sx={{ width: '22px', height: '23px' }}
            >
                <img src={pipeFileName}/>
            </Box>);
        }

        // additional pipe
        if (additionalPipeFlag === APFg_LAST &&
            !props.placemark.hasChildren()
        ) {
            pipeCount++;
            additionalPipeFlag = APFg_ADDED;
            pipeIds.push(IdGenerator.getSingle(3, pipeIds));
        }
    }

    /** COMPONENT */

    let mainBox_padding = '0',
        mainBox_marginBottom = '0';

    if (ValidateValue.is2ArrayEqual(props.depth, [0]) &&
        !props.placemark.client.parent
    ) {
        mainBox_padding = gapPx;
        mainBox_marginBottom = gapPx;
    }

    return <Box
        ref={mainBoxDOM}
        sx={{
            minWidth: '100%',
            maxWidth: 'fit-content',
            paddingTop: mainBox_padding,
            backgroundColor: backgroundColorCode
        }}
    >
        <Box sx={CenterFlex.combine({
            flexCustom: { justifyCenter: false },
            properties: { gap: gapPx, mb: mainBox_marginBottom }
        })}>
            {/* PIPE SIGN */}

            {props.placemark.client.parent ?
                <Box sx={ CenterFlex.get() }>
                    {pipeSigns}
                </Box>
                : <></>
            }

            {/* INTERACTIONS */}

            <Box sx={CenterFlex.combine({ gap: gapPx })}>

                {/* OPEN & CLOSE BUTTONS */}

                {props.children.length > 0 ?
                    <IconButton
                        size="small"
                        sx={{
                            width: iconPx,
                            height: iconPx,
                            ml: isRoot ? gapPx : 0
                        }}
                        onClick={(ev) => {
                            ev.stopPropagation();
                            switchInnerVisibility();
                        }}
                    >
                        { isInnerVisible ? <ExpandLessIcon/> : <ExpandMoreIcon/> }
                    </IconButton>
                    : <></>
                }

                {/* CHECK BOX */}

                <Box
                    onClick={() => { switchBoxChecked(); }}
                    sx={CenterFlex.combine({
                        width: iconPx,
                        height: iconPx,
                        '&:hover': { backgroundColor: '#d0d0d099' }
                    })}
                >
                    {boxCheckedFlag === 0 ?
                        <CheckBoxOutlineBlankIcon sx={checkBoxIconSx}/> :
                        (boxCheckedFlag === 1 ?
                        <IndeterminateCheckBoxIcon sx={checkBoxIconSx}/> :
                        <CheckBoxIcon sx={checkBoxIconSx}/>)
                    }
                </Box>
            </Box>

            {/* TITLE */}
            
            <Box
                ref={titleDOM}
                onClick={props.anchor ?
                    () => {
                        props.anchor();
                        setBackgroundColorCode();
                    }
                    : setBackgroundColorCode
                }
                sx={CenterFlex.combine({
                    minWidth: 'fit-content',
                    paddingLeft: gapPx,
                    paddingRight: gapPx,
                    gap: gapPx,
                    borderRadius: '6px',
                    cursor: 'pointer',
                    '&:hover': {
                        backgroundColor: isSelected ?
                        'white' : CardVars.SELECTED_COLOR_CODE
                    }
                })}
            >
                {props.iconUrl ?
                    /* Image Icon */
                    <img
                        src={props.iconUrl}
                        alt="cb"
                        width={iconSize}
                        height={iconSize}
                    />
                    :
                    /* MUI Icon */
                    (props.iconComponent ?
                        <Box sx={CenterFlex.get()}>
                            {props.iconComponent}
                        </Box>
                        : <></>
                    )
                }
                <Typography sx={{ fontSize: '11px', fontWeight: '500' }}>
                    {props.name}
                </Typography>
            </Box>
        </Box>
        <Box>{ isInnerVisible ? props.children : <></> }</Box>
    </Box>;
}

CardGui.defaultProps = {
    indent: 0,
    children: []
};

