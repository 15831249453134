import * as React from 'react';
import * as IdGenerator from '../utilities/id-generator';

/* Material UI */
import {
    Box,
    Button,
    FormControl,
    IconButton,
    Menu,
    MenuItem
} from '@mui/material/';

/* get default item icon background color */
function getLetterHexColors(letter_in) {

    const letterHex = {
        A: '#eb272c',
        B: '#ffca00',
        C: '#89ce65',
        D: '#370090',
        E: '#e3248a',
        F: '#370090',
        G: '#71005d',
        H: '#ff5800',
        I: '#ffca00',
        J: '#003690',
        K: '#003690',
        L: '#89ce65',
        M: '#e3248a',
        N: '#71005d',
        O: '#ff5800',
        P: '#71005d',
        Q: '#370090',
        R: '#ffca00',
        S: '#ff5800',
        T: '#ff0052',
        U: '#003690',
        V: '#e3248a',
        W: '#ff5800',
        X: '#89ce65',
        Y: '#370090',
        Z: '#eb272c' 
    };

    for (const letter in letterHex) {
        if (letter === letter_in) {
            return letterHex[letter];
        }
    }

    return '#a0a0a0';
}

/**
 * A button that when clicked will display a list of other buttons.
 * 
 * @param {object} props 
 * @param {string} props.title 
 * @param {object} props.icon 
 * @param {object} props.clickedIcon
 * @param {object} props.iconSize 
 * @param {string} props.iconSize.width 
 * @param {string} props.iconSize.height 
 * @param {string} props.width 
 * @param {string[]} props.names 
 * @param {object[]} props.itemIcons 
 * @param {Function[]} props.callbacks 
 * @param {boolean} props.usingDefaultItemIcons 
 * @returns 
 */
export default function MenuGui(props) {

    /**
     *  Consider to set 'names', 'itemIcons',
     *  and 'callbacks' in the same amount
     */

    const isOpen = React.useRef(false);

    const [anchorEl, setAnchorEl] = React.useState(null),
          [iconClicked, setIconClicked] = React.useState(false);

    /* CONTAINER WIDTH */

    const container = React.useRef(null);
    const [mainWidth, setMainWidth] = React.useState(0);

    React.useEffect(() => {
        if (mainWidth === 0 && container) {
            setMainWidth(container.current.clientWidth);
        }
    }, [mainWidth]);

    const getMenuItemWidth = () => (
        props.title ? mainWidth : (props.width ? props.width : 'unset')
    );

    /* INNER FUNCTIONS */

    const handleClick = (event) => {
        isOpen.current = true;
        setAnchorEl(event.currentTarget);
        if (props.clickedIcon) setIconClicked(true);
    };

    const handleClose = () => {
        isOpen.current = false;
        setAnchorEl(null);
        if (props.clickedIcon) setIconClicked(false);
    };

    const menuItem_onClick = (index) => {
        return () => {
            if (isOpen.current) {
                handleClose();

                if (Array.isArray(props.callbacks) &&
                    typeof props.callbacks[index] === 'function'
                ) {
                    props.callbacks[index]();
                }
            }
        };
    };

    /* KEYS & FLAG */

    let menuItemsKeys, existenceString = '';

    if (props.names) {
        menuItemsKeys = IdGenerator.getGroup(props.names.length);
        existenceString = 'names';
    }
    else if (props.itemIcons) {
        menuItemsKeys = IdGenerator.getGroup(props.itemIcons.length);
    }
    else {
        return <Box sx={{
            padding: '2px',
            borderRadius: '5px',
            border: '1px solid black'
        }}>
            {props.title ? props.title : 'ERROR MENU'}
        </Box>;
    }

    /* DEFAULT ICONS */

    let defaultItemIcons;

    if (props.usingDefaultItemIcons &&
        !Array.isArray(props.itemIcons)
    ) {
        if (Array.isArray(props.names)) {
            defaultItemIcons = props.names.map((name) => {

                const firstLetter = name.at(0).toUpperCase();

                return (
                    <Box sx={{
                        width: '30px',
                        height: '30px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderRadius: '15px',
                        backgroundColor: getLetterHexColors(firstLetter),
                        color: 'white',
                        fontWeight: 'bold'
                    }}>
                        {firstLetter}
                    </Box>
                );
            });
        }
    }

    /* THE COMPONENT */

    return <div
        style={{
            width: props.width && props.title ?
                props.width : (props.title ? '100%' : 'unset')
        }}
        ref={container}
    >
        {props.title ?
            <Button
                id="menu-gui-button"
                variant="outlined"
                aria-controls={isOpen.current ? 'menu-gui' : undefined}
                aria-haspopup="true"
                aria-expanded={isOpen.current ? 'true' : undefined}
                onClick={handleClick}
                sx={{ width: '100%' }}
            >
                <FormControl sx={{ flexDirection: 'row', gap: '8px' }}>
                    {props.icon}
                    <Box>{props.title}</Box>
                </FormControl>
            </Button> :

            /* only 'props.icon' */
            
            <IconButton
                id="menu-gui-button"
                aria-controls={isOpen.current ? 'menu-gui' : undefined}
                aria-haspopup="true"
                aria-expanded={isOpen.current ? 'true' : undefined}
                size="small"
                sx={{
                    width: props.iconSize ? props.iconSize.width : 'unset',
                    height: props.iconSize ? props.iconSize.height : 'unset',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
                onClick={handleClick}
            >
                {iconClicked && props.clickedIcon ? props.clickedIcon : props.icon}
            </IconButton>
        }
        <Menu
            id="menu-gui"
            anchorEl={anchorEl}
            open={isOpen.current}
            onClose={handleClose}
            MenuListProps={{
                'aria-labelledby': 'menu-gui-button'
            }}
        >
            {existenceString === 'names' ?
                props.names.map((name, index) => {
                    return (
                        <MenuItem
                            key={menuItemsKeys[index]}
                            onClick={menuItem_onClick(index)}
                            sx={{
                                width: getMenuItemWidth(),
                                display: 'flex',
                                gap: '8px'
                            }}
                        >
                            {Array.isArray(props.itemIcons) ?
                                props.itemIcons[index] :
                                (props.usingDefaultItemIcons ?
                                    defaultItemIcons[index] : <></>
                                )
                            }
                            {name}
                        </MenuItem>
                    );
                }) :

                /* Only 'prop.itemIcons' */

                props.itemIcons.map((icon, index) => {
                    return (
                        <MenuItem
                            key={menuItemsKeys[index]}
                            onClick={menuItem_onClick(index)}
                            sx={{ width: getMenuItemWidth() }}
                        >
                            {icon}
                        </MenuItem>
                    );
                })
            }
        </Menu>
    </div>;
}

