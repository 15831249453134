import Placemark from "../placemark";
import * as VV from "../validate-value";

/**
 * Map of parent-child placemarks as indexes
 */
export default class Depths {
    constructor() {
        this.indexesArray = [];
    }

    /**
     * Initialization. Can be used multiple times.
     * The 'i' is initialized to a length of array
     * because the 'indexesArray' need to keep the
     * previous init when re-init.
     * 
     * @param {Placemark} init_placemark 
     */
    init(init_placemark) {
        if (init_placemark) {

            const pullDepth = (plc_in, lastDepth) => {
                let chd;
                const chdLength = plc_in.client.children.length;

                if (chdLength === 0 || !VV.isNumber(chdLength)) {
                    return;
                }
                else {
                    // children are classification placemarks
                    if (plc_in.type === 'odc' ||
                        plc_in.type === 'pop'
                    ) {
                        chd = VV.cleanArray(plc_in.client.children);
                    }
                    // normal placemark children
                    else chd = plc_in.client.children;
                }

                for (let i = 0; i < chd.length; i++) {
                    const currentDepth = lastDepth.concat(i);
                    this.indexesArray.push(currentDepth);
                    pullDepth(chd[i], currentDepth);
                }
            };

            this.indexesArray.push([0]);
            pullDepth(init_placemark, [0]);
        }
    }

    /**
     * @param {number} index 
     * @returns {number[]}
     */
    get(index) {
        return this.indexesArray[index];
    }

    /**
     * @param {number[]} indexes_in 
     * @returns number
     */
    getIndex(indexes_in) {
        for (let i = 0; i < this.length(); i++) {
            if (VV.is2ArrayEqual(this.indexesArray[i], indexes_in)) {
                return i;
            }
        }
        return -1;
    }

    /**
     * Number of 'indexesArray'
     * @returns {number}
     */
    length() {
        return this.indexesArray.length;
    }

    /**
     * @param {number[]} indexes_in
     * @param {boolean} alsoChildren
     */
    drop(indexes_in, alsoChildren) {

        // return if 'this.indexesArray' is empty
        if (this.length() === 0) return;

        const decrease1 = {
            length: -1,
            index: undefined,
            set: () => {
                decrease1.length = indexes_in.length;
                decrease1.index = indexes_in[indexes_in.length - 1];
            }
        };

        let childrenIndexes = [],
            hasNoChildren = false;

        if (alsoChildren) {
            let i;

            for (i = 0; i < this.length(); i++) {
                if (VV.is2ArrayEqual(this.indexesArray[i], indexes_in)) {

                    for (let j = i + 1; j < this.length(); j++) {
                        if (VV.isArrayContains( this.indexesArray[j], indexes_in )) {
                            childrenIndexes.push([ ...this.indexesArray[j] ]);
                        }
                    }

                    break;
                }
            }

            // the 'indexes_in' is not match any of 'this.indexesArray'
            if (i === this.length() - 1) return;

            hasNoChildren = childrenIndexes.length === 0;
        }

        let currentIndexes = indexes_in;

        /**
         *  This used when 'alsoChildren' is false
         *  but the card implicit parent only has it as a child
         */
        let isImplicitParentRemoved = false;

        const remove = (i) => {

            if (i < this.length() - 1) {
                this.indexesArray = this.indexesArray.slice(0, i).concat(
                    this.indexesArray.slice(i + 1)
                );
            }
            else this.indexesArray = this.indexesArray.slice(0, i);

            return i - 1;
        };

        for (let i = 0; i < this.length(); i++) {
            if (VV.is2ArrayEqual(this.indexesArray[i], currentIndexes)) {
                i = remove(i);

                // has children
                if (alsoChildren && !hasNoChildren) {
                    if (currentIndexes === indexes_in) {
                        currentIndexes = childrenIndexes[0];
                        decrease1.set();
                    }
                    else {
                        if (childrenIndexes.length > 1) {
                            childrenIndexes = childrenIndexes.slice(1);
                            currentIndexes = childrenIndexes[0];
                        }
                        else childrenIndexes = [];
                    }
                }
                // no children
                else decrease1.set();
            }
            else if (
                decrease1.length !== -1 &&
                this.indexesArray[i].length >= decrease1.length &&
                this.indexesArray[i][decrease1.length - 1] > decrease1.index
            ) {
                this.indexesArray[i][decrease1.length - 1]--;
            }
            else if (
                !alsoChildren &&
                !isImplicitParentRemoved &&
                VV.is2ArrayEqual(
                    this.indexesArray[i],
                    [...currentIndexes].slice(0, currentIndexes.length - 1)
                )
            ) {
                i = remove(i);
                decrease1.set();
                isImplicitParentRemoved = true;
            }
        }
    }
}

