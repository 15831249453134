import * as React from 'react';
import ImageListGui from '../../../gui-set/ImageList';
import * as PlacemarkType from '../../../utilities/placemark-type';
import { GeneralDetails } from './types/General';

/* Components */
import { CableDetails } from './types/Cable';
import { ClientDetails } from './types/Client';
import { ClosureDetails } from './types/Closure';
import { OdcDetails } from './types/ODC';
import { OdpDetails } from './types/ODP';
import { PoleDetails } from './types/Pole';
import { PopDetails } from './types/POP';

/* Material UI */
import {
    Box,
    IconButton,
    Typography
} from '@mui/material';

/* MUI Icons */
import CloseIcon from '@mui/icons-material/Close';
import InfoIcon from '@mui/icons-material/Info';

/**
 * @param {object} props 
 * @param {object[]} props.selectedPlacemarkState - react 'useState' pair
 * @param {boolean} props.empty 
 * @returns {object} React Component
 */
export default function Details(props) {

    let formTypeSx,
        formTypeSx_box,
        UsedSpecial = <></>;

    if (!props.empty) {
        /* STYLES */

        formTypeSx = {
            padding: '10px 20px 10px 20px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            gap: '20px',
            backgroundColor: 'white'
        };

        formTypeSx_box = {
            width: 1,
            marginBottom: '10px',
            fontSize: '12px',
            color: '#0060ff',
            borderBottom: '1px solid #0060ff'
        };

        /* COMPONENTS */

        switch ( props.selectedPlacemarkState[0].type.toLowerCase() ) {
            case 'cable': {
                UsedSpecial = <CableDetails/>;
            break}
            case 'client': {
                UsedSpecial = <ClientDetails/>;
            break}
            case 'closure': {
                UsedSpecial = <ClosureDetails/>;
            break}
            case 'odc': {
                UsedSpecial = <OdcDetails/>;
            break}
            case 'odp': {
                UsedSpecial = <OdpDetails/>;
            break}
            case 'pole': {
                UsedSpecial = <PoleDetails/>;
            break}
            case 'pop': {
                UsedSpecial = <PopDetails/>;
            break}
            default: {
                UsedSpecial = <></>;
            }
        }
    }

    /* Complete return component */

    return <Box sx={Object.assign(
        {
            width: '100%',
            height: '100%'
        },
        props.empty ?
        {
            display:'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '40px'
        } : {}
    )}>
        {!props.empty ?
            /* CLOSE BUTTON */

            <> <Box sx={{
                width: '100%',
                height: '30px',
                position: 'relative',
                display: 'flex',
                alignItems: 'center',
                backgroundColor: 'white'
            }}>
                <IconButton
                    sx={{
                        position: 'absolute',
                        right: '5px',
                        transform: 'scale(0.7)'
                    }}
                    onClick={() => {
                        props.selectedPlacemarkState[1](null);
                    }}
                >
                    <CloseIcon/>
                </IconButton>
            </Box>

            {/* MEDIA FILES */}

            <ImageListGui
                title={{
                    iconUrl: PlacemarkType.getIconUrl(props.selectedPlacemarkState[0].type),
                    name: props.selectedPlacemarkState[0].name
                }}
                width="100%"
                height="60%"
                items={props.selectedPlacemarkState[0].imagesUrl}
            />

            {/* TEXT */}

            <Box sx={{
                width: 1,
                backgroundColor: 'white'
            }}>
                {/* SPECIAL */}
                <Box sx={formTypeSx}>
                    <Box sx={formTypeSx_box}>Spesial</Box>
                    {UsedSpecial}
                </Box>

                {/* GENERAL */}
                <Box sx={formTypeSx}>
                    <Box sx={formTypeSx_box}>Umum</Box>
                    <GeneralDetails selectedPlacemark={props.selectedPlacemarkState[0]}/>
                </Box>
            </Box> </>
            :
            /* EMPTY DATA (LIST NOT SELECTED) */

            <> <InfoIcon sx={{
                transform: 'scale(3)',
                opacity: 0.25
            }}/>
            <Typography
                variant="h5"
                align="center"
                color="#999999"
            >
                Rincian akan <br/> ditampilkan disini
            </Typography> </>
        }
    </Box>;
}

