import * as React from 'react';
import * as ValidateValue from '../utilities/validate-value';
import * as CenterFlex from '../utilities/center-flex';
import FloatingBoard from './FloatingBoard';
import FloatingContainer from './floating-container';

/**
 * The maximum unabbreviated number is absolute value of 1000.
 * More than that will using 'k, m, b, t, 10^12, 10^15, ...'
 * 
 * @param {number} nb 
 * @returns {number[][]} Fraction number with index for large 'largeNumberUnits' and 'powerOf10'
 */
function brief(nb) {
    if (ValidateValue.isNumber(nb)) {
        const digits = Math.floor(Math.log10(Math.abs(nb))) + 1;
    
        if (digits > 3) {
            let index = 0;
            
            while (true) {

                // the 'nb' is 1000 or beyond
                if (digits > (index + 1) * 3 && digits <= (index + 2) * 3) {
                    return [
                        parseFloat( (nb / Math.pow(10, (index + 1) * 3)).toFixed(2) ),
                        index
                    ];
                }
    
                index++;
            }
        }
        
        // the 'nb' is below 1000
        return [parseFloat(nb.toFixed(2)), -1];
    }

    // the 'nb' is not a number
    return [NaN, -1];
}

/**
 * Display large number as label component
 * in abbreviated form followed by unit.
 * For example, 1000 is 1k.
 * 
 * @param {object} props 
 * @param {number} props.number 
 * @param {FloatingContainer} props.floatingContainer 
 * @returns {object} Label Component
 */
export default function NumbersAbbreviation(props) {

    let largeNumberUnits = [
        'k', // kilo
        'm', // million
        'b', // billion
        't'  // trillion
    ];

    // from 'Quadrillion' to 'Centillion'
    const powerOf10 = [];

    for (let i = 15; i <= 303 ; i += 3) {
        powerOf10.push(i);
    }

    // fraction number and 'largeNumberUnits' or 'powerOf10' index
    const frac_n_index = brief(props.number);

    if (ValidateValue.isNumber(frac_n_index[0])) {

        let labelSize;
        const strNumLen = frac_n_index[0].toString().length;

        // label style template
        const getLabelStyle = () => { return {
            fontSize: labelSize + 'px',
            fontWeight: 'bold',
            color: '#5f5f5f',
            borderRadius: '5px',
            border: '1px dotted #5f5f5f99',
            backgroundColor: '#5f5f5f10',
            cursor: 'copy'
        }; };

        // copy the 'props.number' to clipboard
        const copyNumber = (ev) => {
            navigator.clipboard.writeText(props.number);

            // pop-up board with immediately fade out
            props.floatingContainer.show(
                <FloatingBoard sx={{
                    width: '120px',
                    height: '20px',
                    top: ev.clientY,
                    left: ev.clientX,
                    fontSize: '12px'
                }}>
                    {'Copied to clipboard'}
                </FloatingBoard>,
                false,
                true
            );
        };

        // responsive label sizes
        const simple_labelSizes = [11, 12, 13],
              unit_labelSizes = [10, 11, 12],
              pow10Unit_labelSizes = [9, 10, 11];

        const testLabelSize = (sizes) => {
            if (strNumLen >= 6) labelSize = sizes[0];
            else if (strNumLen === 5) labelSize = sizes[1];
            else labelSize = sizes[2];
        };

        // not using unit (below 1000) [simple component]
        if (frac_n_index[1] === -1) {
            testLabelSize(simple_labelSizes);
            return <div
                style={getLabelStyle()}
                onClick={copyNumber}
            >{ frac_n_index[0] }</div>
        }
        // using unit
        else {
            // power 10 unit template component
            const getPow10Unit = (pow, lbSz) => <div style={{
                display: 'flex',
                gap: '1px',
                fontSize: (lbSz * 0.9) + 'px'
            }}>
                x10
                <div style={{ fontSize: (lbSz / 2) + 'px' }}> {pow} </div>
            </div>;

            /** Fit label size with actions box */

            let usingPow10Unit = false;

            if (frac_n_index[1] >= largeNumberUnits.length) {
                usingPow10Unit = true;
            }

            if (usingPow10Unit) testLabelSize(pow10Unit_labelSizes);
            else testLabelSize(unit_labelSizes);

            /** Unit Component */

            // placed behind the number
            let unit;

            // below 1 quadrillion
            if (frac_n_index[1] < largeNumberUnits.length) {
                unit = largeNumberUnits[frac_n_index[1]];
            }
            // 1 quadrillion or beyond (rarely used)
            else if (frac_n_index[1] < powerOf10.length) {
                frac_n_index[1] -= largeNumberUnits.length;
                unit = getPow10Unit(powerOf10[frac_n_index[1]], labelSize);
            }
            // exceed centillion (rarely used)
            else unit = getPow10Unit('n', labelSize);

            /** Advanced component */

            return <div
                style={CenterFlex.combine( getLabelStyle() )}
                onClick={copyNumber}
            >
                {frac_n_index[0]}
                {unit}
            </div>;
        }
    }
    // the 'props.number' is not a number
    else return <div>NaN</div>;
}

