import * as React from 'react';
import CardGui from '../../../gui-set/Card';
import ContainerCard from './container-card';
import { generateRealClassyCards } from './real-classy-cards';
import CheckBoxStatus from '../../../utilities/status/check-box-status';
import ParentChildStatus from '../../../utilities/status/parent-child-status';
import Placemark from '../../../utilities/placemark';
import RerenderList from '../../../utilities/status/rerender-list';
import * as IdGen from '../../../utilities/id-generator';
import * as PlacemarkType from '../../../utilities/placemark-type';
import * as ValidateValue from '../../../utilities/validate-value';

/**
 * @param {object} props 
 * @param {string} props.mainTitle 
 * @param {object} props.mainIcon 
 * @param {object} props.aRowPlacemarks 
 * @param {Function} props.setSelectedPlacemark 
 * @param {boolean} props.isNoContainer 
 * @param {ParentChildStatus} props.cardsOpenStatus 
 * @param {CheckBoxStatus} props.checkBoxStatus 
 * @param {ParentChildStatus} props.selectedCardColorStatus 
 * @param {object} props.placemarksDataState 
 * @param {RerenderList} props.upwardCardRerenderList 
 * @returns {object} React Component
 */
export default function MainCard(props) {

    /** Card Re-render */
    const cardRerenderList = new RerenderList(
        React.useState(false),
        props.upwardCardRerenderList
    );

    // 'CardGui' components
    let cards = [];

    // main placemark (pseudo)
    let resultPlacemark = new Placemark(
        {client: { children: props.aRowPlacemarks.containers }}
    );

    // the fake placemarks ('isPseudo' is true)
    let allClassyPlacemarks = [];

    // represent the depth
    const sharedDepths = props.cardsOpenStatus.depths;

    /**
     * Set to empty before initialization to prevent
     * exceeding the number of possible index arrays (total placemarks count).
     */
    if (sharedDepths.current) {
        sharedDepths.current.drop([0], true);
        sharedDepths.current.init(resultPlacemark);
    }

    // initialize flags
    props.cardsOpenStatus.init(false);
    props.checkBoxStatus.init(0);
    props.selectedCardColorStatus.init(false);

    for (let i = 0; i < props.aRowPlacemarks.containers.length; i++) {

        /* Classification Cards */

        const dummyClassyCards = new Array(PlacemarkType.flags.length);

        // container placemark
        const containerPlacemark = props.aRowPlacemarks.containers[i];
        containerPlacemark.client.parent = resultPlacemark

        // classification stuff
        const classyObjectKeys = Object.keys(props.aRowPlacemarks.children[i]);
        const classyIds = IdGen.getGroup(classyObjectKeys.length);
        const classyPlacemarks = new Array(classyObjectKeys.length);

        /* 'dummyClassyCards' follow the 'PlacemarkType' flags order */

        for (let j = 0; j < classyObjectKeys.length; j++) {

            const typeTitle = PlacemarkType.getTranslatedTitle( classyObjectKeys[j], 'id' );
            const typeIndex = PlacemarkType.getIndexOrder( classyObjectKeys[j] );

            if (typeIndex !== -1) {
                const limitCount = 100;

                let limitedVisiblePlacemarks = props.aRowPlacemarks.children[i][classyObjectKeys[j]],
                    hiddenPlacemarks = [];

                if (limitedVisiblePlacemarks.length > limitCount) {
                    hiddenPlacemarks = limitedVisiblePlacemarks.slice(limitCount);
                    limitedVisiblePlacemarks = limitedVisiblePlacemarks.slice(0, limitCount);
                }

                dummyClassyCards[typeIndex] = {
                    key: classyIds[j],
                    title: typeTitle,
                    iconUrl: PlacemarkType.getIconUrl(classyObjectKeys[j]),
                    visiblePlacemarks: limitedVisiblePlacemarks,
                    hiddenPlacemarks
                };

                classyPlacemarks[typeIndex] = new Placemark({
                    client: {
                        parent: containerPlacemark,
                        children: dummyClassyCards[typeIndex].visiblePlacemarks,
                        friends: classyPlacemarks
                    }
                });

                // set classy as placemarks parent
                for (const plc of props.aRowPlacemarks.children[i][classyObjectKeys[j]]) {
                    plc.client.parent = classyPlacemarks[typeIndex];
                }
            }
        }

        // fill each 'dummyClassyCards' with each of 'classyPlacemarks'
        for (let j = 0; j < dummyClassyCards.length; j++) {
            if (dummyClassyCards[j]) {
                dummyClassyCards[j].selfPlacemark = classyPlacemarks[j];
            }
        }

        // set container parent and children
        containerPlacemark.client.parent = resultPlacemark;
        containerPlacemark.client.children = classyPlacemarks;

        allClassyPlacemarks = allClassyPlacemarks.concat(
            ValidateValue.cleanArray(classyPlacemarks)
        );

        // there should be only one iteration
        if (props.isNoContainer) {

            cards = generateRealClassyCards(
                dummyClassyCards,
                props.setSelectedPlacemark,
                containerPlacemark,
                [0, i],
                props.cardsOpenStatus,
                props.checkBoxStatus,
                props.selectedCardColorStatus,
                cardRerenderList
            );

            props.aRowPlacemarks.containers[0].client.parent = resultPlacemark;
            break;
        }

        // container cards (POP or ODC cards)
        cards.push(<ContainerCard
            key={containerPlacemark.id}
            containerPlacemark={containerPlacemark}
            aRowPlacemarks={props.aRowPlacemarks}
            setSelectedPlacemark={props.setSelectedPlacemark}
            dummyClassyCards={dummyClassyCards}
            depth={[0, i]}
            cardsOpenStatus={props.cardsOpenStatus}
            checkBoxStatus={props.checkBoxStatus}
            selectedCardColorStatus={props.selectedCardColorStatus}
            upwardCardRerenderList={cardRerenderList}
        />);
    }

    const children_1D_buffer = [ [].concat(...props.aRowPlacemarks.children), [] ];

    for (const arr of children_1D_buffer[0]) {
        for (const key in arr) {
            children_1D_buffer[1] = children_1D_buffer[1].concat(arr[key]);
        }
    }

    props.placemarksDataState.setter(
        [resultPlacemark]
        .concat(props.aRowPlacemarks.containers)
        .concat(allClassyPlacemarks)
        .concat(children_1D_buffer[1])
    );

    return <CardGui
        name={props.mainTitle}
        iconComponent={props.mainIcon}
        order="body"
        indent={0}
        depth={[0]}
        openStatus={props.cardsOpenStatus}
        checkBoxStatus={props.checkBoxStatus}
        selectedColorStatus={props.selectedCardColorStatus}
        innerVisibilityInit={true}
        placemark={resultPlacemark}
        rerenderList={cardRerenderList}
        children={cards}
    />;
}

