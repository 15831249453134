import * as React from 'react';
import ButtonGui from '../../gui-set/Button';
import MenuGui from '../../gui-set/Menu';
import SelectGui from '../../gui-set/Select';
import ShadeShield from '../../gui-set/ShadeShield';
import * as DrawOptions from '../map/draw-options';
import * as PlacemarkType from '../../utilities/placemark-type';
import * as KML from '../map/kml';
import './Draw.css';

/* Material UI Icons */
import AddIcon  from '@mui/icons-material/Add';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import CropIcon  from '@mui/icons-material/Crop';
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/Download';
import DrawIcon from '@mui/icons-material/Draw';
import LockIcon from '@mui/icons-material/Lock';
import UploadIcon from '@mui/icons-material/Upload';

function openFile(fileOpener, callback) {

    // DOM container clicked
    fileOpener.click();

    // open button or selected file clicked from popped up file list window
    fileOpener.addEventListener(
        'change',
        (ev) => {
            const [file] = ev.target.files;
            const reader = new FileReader();

            if (file) reader.readAsText(file);

            reader.addEventListener('load', () => {
                callback(reader.result);
            });
        }
    );
}

/**
 * @param {object} props 
 * @param {object} domSet
 * @param {Function} setVersatileMode
 * @param {Function} setType
 * @param {object} drawState
 * @param {object} shadeShieldGlobalState
 * @param {Function} setPlacemarksImport
 * @param {Function} setImportStatus
 * @returns {object} React Component
 */
export default function Draw(props) {

    const container = React.useRef(null),
          fileOpener = React.useRef(null);

    React.useEffect(() => {
        props.domSet.current.add(
            'Draw',
            container.current
        );
    }, []);

    const [isDirectDrawAdd, setIsDirectDrawAdd] = React.useState(false);
    const [isCropping, setIsCropping] = React.useState(false);
    const selectGui_add_clearValue = React.useRef(null);

    const isCoil1 = () => props.drawState.value.taskName === 'cable-addition-1',
          isCoil2 = () => props.drawState.value.taskName === 'cable-addition-2';

    /* DOWNLOAD BUTTON */
    const DownloadButton = (
        <MenuGui
            title="Unduh"
            icon={<DownloadIcon/>}
            width="150px"
            names={[
                'KMZ/KML',
                'XLSX (Microsoft Excel)',
                'ODS (LibreOffice Calc)'
            ]}
        />
    );

    /* UPLOAD BUTTON */
    const UploadButton = (
        <MenuGui
            title="Unggah"
            icon={<UploadIcon/>}
            width="150px"
            names={[
                <div>
                    <input
                        type="file"
                        accept=".kmz,.kml"
                        style={{display: 'none'}}
                        ref={fileOpener}
                    />
                    KMZ/KML
                </div>,
                'CSV',
                'TXT'
            ]}
            callbacks={[
                () => {
                    openFile(fileOpener.current, (result) => {
                        fetch('http://127.0.0.1:9000/kml', {
                            method: 'POST',
                            headers: { 'Content-Type': 'text/plain' },
                            body: result
                        })
                        .then(response => response.json())
                        .then(parsedKML => {
                            props.setPlacemarksImport(
                                parsedKML.title,
                                parsedKML.data
                            );

                            props.setVersatileMode('import');
                            props.setImportStatus('ready');
                        })
                        .catch(error => console.error(error));
                    });
                },
                () => {
                    fileOpener.current.click();
                },
                () => {
                    fileOpener.current.click();
                }
            ]}
        />
    );

    /* Names are sorted by highest reports frequency */
    const AddName = {
        labels: PlacemarkType.flags.map(
            (fg) => PlacemarkType.getTranslatedTitle(fg, 'id')
        ),
        types: PlacemarkType.flags,
        getType: (label_in) => {
            for (let i = 0; i < AddName.labels.length; i++) {
                if (label_in === AddName.labels[i]) {
                    return AddName.types[i];
                }
            }
        }
    };

    //________|
    // RETURN |
    //________|

    return <div className="Options-Draw" ref={container}>
        <div className="Options-Draw-LeftSide">
            {isCropping ?
                /* CROPPING MENU */
                <> <ButtonGui
                    name="SEMBUNYIKAN"
                    icon={<LockIcon/>}
                    width="150px"
                    onClick={() => {}}
                />
                <ButtonGui
                    name="HAPUS"
                    icon={<DeleteIcon/>}
                    width="150px"
                    onClick={() => {}}
                /> </>
                :
                /* NORMAL MENU */
                <> <SelectGui
                    name="Tambahkan"
                    width="150px"
                    titleFontSize="15px"
                    namesList={AddName.labels}
                    task={(value, clearValue) => {
                        const plcType = AddName.getType(value);

                        props.setVersatileMode('form');
                        props.setType(plcType);
                        setIsDirectDrawAdd(true);

                        if (!selectGui_add_clearValue.current) {
                            selectGui_add_clearValue.current = clearValue;
                        }

                        // will emerge new (temporary) marker at the center of 'Map'
                        props.drawState.setter(DrawOptions.getAddition( plcType ));
                    }}
                    icon={<AddIcon/>}
                />
                {!isDirectDrawAdd ?
                    /* CROPPING BUTTON */
                    <ButtonGui
                        name="POTONG"
                        icon={<CropIcon/>}
                        width="150px"
                        onClick={() => {
                            props.shadeShieldGlobalState.setter('crop');
                            setIsCropping(true);
                        }}
                    /> :
                    <></>
                } </>
            }
        </div>
        <div className="Options-Draw-RightSide">
            {isDirectDrawAdd ?
                /* SAVE BUTTON */
                <> <ButtonGui
                    name={isCoil1() ? 'TITIK KE-2' : (isCoil2() ? 'GAMBAR JALUR' : 'SIMPAN' )}
                    icon={isCoil1() ? <ArrowForwardIcon/> : (isCoil2() ? <DrawIcon/> : <CheckIcon/> )}
                    width="150px"
                    onClick={() => {
                        if (isCoil1()) {
                            /*
                            *   Will emerge the second new (temporary)
                            *   'coil' marker at the center of 'Map'.
                            */
                            props.drawState.setter(DrawOptions.getAddition( 'cable', 2 ));
                        }
                        else if (isCoil2()) {
                            /* after auto drawing path (waiting to save) */
                            props.drawState.setter(DrawOptions.getAddition( 'cable', 3 ));
                        }
                        else {
                            /* Set display back to details */
                            props.setVersatileMode('details');
                            setIsDirectDrawAdd(false);
                            selectGui_add_clearValue.current();

                            /* 'Map' will save new markers */
                            props.drawState.setter(DrawOptions.getInactive(true));
                        }
                    }}
                />
                {/* CANCEL BUTTON */}
                <ButtonGui
                    name="BATAL"
                    icon={<ClearIcon/>}
                    width="150px"
                    onClick={() => {

                        // set display back to details
                        props.setVersatileMode('details');
                        setIsDirectDrawAdd(false);
                        selectGui_add_clearValue.current();

                        // change 'Map' cursor
                        props.drawState.setter(DrawOptions.getInactive(false));
                    }}
                /> </>
                :
                (isCropping ?
                    /* CROPPING MENU */
                    <> {DownloadButton}
                    <ButtonGui
                        name="BATAL"
                        icon={<ClearIcon/>}
                        width="150px"
                        onClick={() => {
                            props.shadeShieldGlobalState.setter('');
                            setIsCropping(false);
                        }}
                    /> </>
                    :
                    (!isDirectDrawAdd ?
                        /* UPLOAD & DOWNLOAD BUTTONS */
                        <>
                            {UploadButton}
                            {DownloadButton}
                        </> :
                        <></>
                    )
                )
            }
        </div>
        {props.shadeShieldGlobalState.value === 'full' ?
            <ShadeShield
                container={container.current}
                topComponents={props.domSet.current.query('Account')}
                leftComponents={props.domSet.current.multiQuery( ['List', 'Map'] )}
                gaps={[10, 10, 5, 0]}
                offsetCovered
            /> :
            <></>
        }
    </div>;
}

