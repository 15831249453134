/* Material UI */
import { IconButton } from '@mui/material';

/**
 * A clickable icon.
 * Don't use as 'ButtonGui' icon property.
 * 
 * @param {object} props 
 * @param {component} props.icon 
 * @param {string} props.diameter 
 * @param {string} props.predefinedSize - "small" | "medium" | "large"
 * @param {Function} props.onClick 
 * @returns {object} React Component
 */
export default function IconButtonGui(props) {
    return <IconButton
        size={props.predefinedSize}
        sx={{ width: props.diameter, height: props.diameter }}
        onClick={props.onClick}
    >
        {props.icon}
    </IconButton>;
}

IconButtonGui.defaultProps = {
    diameter: 'unset',
    predefinedSize: 'small',
    onClick: () => {}
};

