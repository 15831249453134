/**
 * @param {boolean} isSavingMarker 
 * @returns {object}
 */
function getInactive(isSavingMarker = false) {
    return {
        plcType: '',
        taskName: isSavingMarker ? 'save' : 'cancel'
    };
}

/**
 * 'coilCtr' specified only on cable addition.
 * 
 * Available 'taskName':
 * 
 * "addition", "cable-addition-1", "cable-addition-2", "cable-addition-3"
 * 
 * @param {string} plcType_in 
 * @param {number} coilCtr 
 * @returns {object}
 */
function getAddition(plcType_in, coilCtr = 1) {
    return {
        plcType: plcType_in === 'coil' || plcType_in === 'cable' ? (
            coilCtr < 3 ? 'coil' : 'cable'
        ) : plcType_in,
        taskName: (
            (plcType_in === 'cable' ? 'cable-' : '') +
            'addition' +
            (plcType_in === 'cable' ? `-${coilCtr}` : '')
        )
    };
}

/**
 * @param {string} plcType_in 
 * @returns {object}
 */
function getSelectection(plcType_in) {
    return {
        plcType: plcType_in,
        taskName: 'selection'
    };
}

export {
    getInactive,
    getAddition,
    getSelectection
};

