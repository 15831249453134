import * as React from 'react';
import Placemark from '../../../utilities/placemark';
import CardGui from '../../../gui-set/Card';
import { getOrderFlag } from '../../../gui-set/Card';

/** MUI Icons */
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import RerenderList from '../../../utilities/status/rerender-list';

/**
 * Filter available 'dummyClassyCards'
 * members into 'realClassyCards'
 * 
 * @param {object[]} dummyClassyCards 
 * @param {Function} setSelectedPlacemark 
 * @param {object} parentPlacemark 
 * @param {number[]} depth 
 * @param {object} cardsOpenStatus 
 * @param {object} checkBoxStatus 
 * @param {object} selectedCardColorStatus 
 * @param {RerenderList} upwardCardRerenderList 
 * @returns {object[]} React Components
 */
export function generateRealClassyCards(
    dummyClassyCards,
    setSelectedPlacemark,
    parentPlacemark,
    depth,
    cardsOpenStatus,
    checkBoxStatus,
    selectedCardColorStatus,
    upwardCardRerenderList
) {
    let rawClassyCards = [];

    for (let i = 0; i < dummyClassyCards.length; i++) {
        if (dummyClassyCards[i]) {
            rawClassyCards.push(dummyClassyCards[i]);
        }
    }

    return rawClassyCards.map((element, index) => <ClassyCard
        key={element.key}
        depth={depth.concat([index])}
        cardsOpenStatus={cardsOpenStatus}
        checkBoxStatus={checkBoxStatus}
        selectedCardColorStatus={selectedCardColorStatus}
        setSelectedPlacemark={setSelectedPlacemark}
        rawClassyLength={rawClassyCards.length}
        element={element}
        parentPlacemark={parentPlacemark}
        upwardCardRerenderList={upwardCardRerenderList}
    />);
}

/**
 * Transform 'rawClassyCards' to 'CardGui' array
 * 
 * @param {object} props 
 * @param {Function} props.setSelectedPlacemark 
 * @param {number} props.rawClassyLength 
 * @param {object} props.element 
 * @param {object} props.parentPlacemark
 * @param {number[]} depth 
 * @param {object} props.cardsOpenStatus 
 * @param {object} props.checkBoxStatus 
 * @param {object} props.selectedCardColorStatus 
 * @param {RerenderList} props.upwardCardRerenderList 
 * @returns {object} React Component
 */
function ClassyCard(props) {

    // pseudo placemark for show hidden placemarks button
    let showHiddenPlacemark;

    if (props.element.hiddenPlacemarks.length > 0) {
        showHiddenPlacemark = new Placemark({
            client: {
                parent: props.element.selfPlacemark,
                friends: props.element.visiblePlacemarks
            }
        });
    }

    /** Card Re-render */
    const cardRerenderList = new RerenderList(
        React.useState(false),
        props.upwardCardRerenderList
    );

    return <CardGui
        name={props.element.title}
        iconUrl={props.element.iconUrl}
        order={getOrderFlag(
            props.depth[props.depth.length - 1],
            props.rawClassyLength
        )}
        indent={2}
        depth={props.depth}
        openStatus={props.cardsOpenStatus}
        checkBoxStatus={props.checkBoxStatus}
        selectedColorStatus={props.selectedCardColorStatus}
        placemark={props.element.selfPlacemark}
        rerenderList={cardRerenderList}
    >
        {props.element.visiblePlacemarks.map((placemark, index) =>
            <PlacemarkCard
                key={placemark.id}
                setSelectedPlacemark={props.setSelectedPlacemark}
                element={props.element}
                placemark={placemark}
                depth={props.depth.concat([index])}
                cardsOpenStatus={props.cardsOpenStatus}
                checkBoxStatus={props.checkBoxStatus}
                selectedCardColorStatus={props.selectedCardColorStatus}
                upwardCardRerenderList={cardRerenderList}
            />
        )}
        {props.element.hiddenPlacemarks.length > 0 ?
            <ShowHiddenCard
                key={showHiddenPlacemark.id}
                title="Tampilkan 100 lainnya"
                placemark={showHiddenPlacemark}
                depth={props.depth.concat([ props.element.visiblePlacemarks.length ])}
                cardsOpenStatus={props.cardsOpenStatus}
                checkBoxStatus={props.checkBoxStatus}
                selectedCardColorStatus={props.selectedCardColorStatus}
                upwardCardRerenderList={cardRerenderList}
            />
            : <></>
        }
    </CardGui>;
}

/**
 * Placemark card inside classification card
 * 
 * @param {object} props 
 * @param {Function} props.setSelectedPlacemark 
 * @param {object} props.element 
 * @param {object} props.placemark 
 * @param {number[]} props.depth 
 * @param {object} props.cardsOpenStatus 
 * @param {object} props.checkBoxStatus 
 * @param {object} props.selectedCardColorStatus 
 * @param {RerenderList} props.upwardCardRerenderList 
 * @returns {object} React Component
 */
function PlacemarkCard(props) {

    /** Card Re-render */
    const cardRerenderList = new RerenderList(
        React.useState(false),
        props.upwardCardRerenderList
    );

    let orderFlag = getOrderFlag(
        props.depth[props.depth.length - 1],
        props.element.visiblePlacemarks.length
    );

    if (props.element.hiddenPlacemarks.length > 0 &&
        orderFlag === 'tail'
    ) {
        orderFlag = undefined;
    }

    return <CardGui
        name={props.placemark.name}
        iconUrl={props.element.iconUrl}
        order={orderFlag}
        indent={3}
        depth={props.depth}
        openStatus={props.cardsOpenStatus}
        checkBoxStatus={props.checkBoxStatus}
        selectedColorStatus={props.selectedCardColorStatus}
        placemark={props.placemark}
        anchor={() => { props.setSelectedPlacemark(props.placemark); }}
        rerenderList={cardRerenderList}
    />;
}

/**
 * @param {object} props 
 * @param {string} props.title 
 * @param {object} props.placemark 
 * @param {number[]} props.depth 
 * @param {object} props.cardsOpenStatus 
 * @param {object} props.checkBoxStatus 
 * @param {object} props.selectedCardColorStatus 
 * @param {RerenderList} props.upwardCardRerenderList 
 * @returns {object} React Component
 */
function ShowHiddenCard(props) {

    /** Card Re-render */
    const cardRerenderList = new RerenderList(
        React.useState(false),
        props.upwardCardRerenderList
    );

    return <CardGui
        name={props.title}
        iconComponent={<MoreHorizIcon/>}
        order="tail"
        indent={3}
        placemark={props.placemark}
        depth={props.depth}
        openStatus={props.cardsOpenStatus}
        checkBoxStatus={props.checkBoxStatus}
        selectedColorStatus={props.selectedCardColorStatus}
        rerenderList={cardRerenderList}
    />;
}

