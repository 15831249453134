import * as React from 'react';

/* Material UI */
import {
    Box,
    IconButton,
    ImageList,
    ImageListItem,
    ImageListItemBar
} from '@mui/material';

/* MUI Icons */
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import ClearIcon from '@mui/icons-material/Clear';
import DeleteIcon from '@mui/icons-material/Delete';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';

/**
 * props: {
 *   item (object { url, name, date, author, key })
 *   selectedKeysState (object { 'value', 'setter' })
 *   imagesSelectedStateSetters (useRef { function array })
 * }
 */
function MyImageListItem(props) {
    const [isSelected, setIsSelected] = React.useState(false);

    React.useEffect(() => {
        const setterArr = props.imagesSelectedStateSetters.current;

        if (setterArr.length === 0) {
            setterArr.push(setIsSelected);
        }
        else {
            for (let i = 0; i < setterArr.length; i++) {
                if (setterArr[i] === setIsSelected) {
                    break;
                }
                else if (i === setterArr.length - 1) {
                    setterArr.push(setIsSelected);
                }
            }
        }
    }, []);

    React.useEffect(() => {

        if (isSelected) {
            props.selectedKeysState.setter(
                props.selectedKeysState.value.concat([ props.item.url ])
            );
        }
        else {
            for (let i = 0; i < props.selectedKeysState.value.length; i++) {
                if (props.selectedKeysState.value[i] === props.item.url) {

                    props.selectedKeysState.setter(
                        props.selectedKeysState.value.slice(0, i).concat(
                            props.selectedKeysState.value.slice(i+1)
                        )
                    );

                    break;
                }
            }
        }

    }, [isSelected]);

    return (
        <ImageListItem>
            <img
                src={props.item.url}
                alt={props.item.name}
                loading="lazy"
            />
            <ImageListItemBar
                sx={{
                    backgroundColor: isSelected ? '#000000bb' : '#00000055'
                }}
                title={props.item.name}
                subtitle={
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '5px'
                    }}>
                        <Box>{props.item.time.date}</Box>
                        <Box>{props.item.time.hour}</Box>
                    </Box>
                }
                position="top"
                actionIcon={
                    <IconButton
                        aria-label={`Pilih ${props.item.name}`}
                        size="small"
                        sx={{ color: 'white' }}
                        onClick={() => { setIsSelected(!isSelected); }}
                    >
                        {isSelected ?
                            <CheckBoxIcon sx={{ opacity: 0.75 }}/> :
                            <CheckBoxOutlineBlankIcon sx={{ opacity: 0.75 }}/>
                        }
                    </IconButton>
                }
                actionPosition="left"
            />
        </ImageListItem>
    );
}

/**
 * props: {
 *  title (object { iconUrl, name })
 *  width (string or number)
 *  height (string or number)
 *  items (object array)
 * }
 */
export default function ImageListGui(props) {
    
    const imagesSelectedStateSetters = React.useRef([]);
    const [selectedKeys, setSelectedKeys] = React.useState([]);
    const isItemsAvailable = props.items && props.items.length > 0;

    return (
        <Box
            sx={{
                width: props.width,
                height: isItemsAvailable ? props.height : '100px'
            }
        }>
            {/* TOP POPPED PANEL */}

            {selectedKeys.length > 0 ?
                <Box sx={{
                    width: '100%',
                    height: '40px',
                    paddingLeft: '5px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    gap: '5px',
                    boxSizing: 'border-box',
                    backgroundColor: '#a0a0a0',
                    color: 'white',
                    fontWeight: '600'
                }}>
                    <IconButton
                        aria-label={'Batalkan Pilihan'}
                        size="small"
                        sx={{ color: 'white' }}
                        onClick={() => {
                            setSelectedKeys([]);

                            for (const setter of imagesSelectedStateSetters.current) {
                                setter(false);
                            }
                        }}
                    >
                        <ClearIcon/>
                    </IconButton>
                    <Box>
                        {`${selectedKeys.length} Terpilih`}
                    </Box>
                </Box> :
                <></>
            }

            {/* MAIN VIEW */}

            <Box sx={{
                width: '100%',
                height: (selectedKeys.length > 0 ? 'calc(100% - 40px)' : '100%')
            }}>
                {/* IMAGES LIST */}

                {isItemsAvailable ?
                    <ImageList
                        sx={{
                            width: '100%',
                            height: '100%',
                            margin: 0,
                            overflowY: 'scroll',
                            /*
                            * Promote the list into its own layer in Chrome.
                            * This costs memory, but helps keeping high FPS.
                            */
                            transform: 'translateZ(0)'
                        }}
                        gap={1}
                    >
                        {props.items.map((item) =>
                            <MyImageListItem
                                key={item.url}
                                item={item}
                                selectedKeysState={{
                                    value: selectedKeys,
                                    setter: setSelectedKeys
                                }}
                                imagesSelectedStateSetters={imagesSelectedStateSetters}
                            />
                        )}
                    </ImageList>
                    :
                    /* EMPTY IMAGES */

                    <Box sx={{
                        width: '100%',
                        height: '100px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: '#a0a0a0'
                    }}>
                        <InsertPhotoIcon sx={{transform: 'scale(2)'}}/>
                    </Box>
                }

                {/* FLOATING BOTTOM */}

                <Box sx={{
                    width: 1,
                    height: 0,
                    position: 'relative',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                }}>
                    {/* TITLE BOARD */}

                    <Box sx={{
                        padding: '5px 10px 5px 10px',
                        position: 'absolute',
                        left: '0',
                        bottom: '0',

                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: '6px',

                        borderRadius: '0 10px 0 0',
                        fontSize: '24px',
                        backgroundColor: 'white'
                    }}>
                        {props.title.iconUrl ?
                            <img
                                src={props.title.iconUrl}
                                width="28"
                                height="28"
                            />
                            : <></>
                        }
                        {props.title.name}
                    </Box>

                    {/* ACTION BUTTONS */}

                    <Box sx={{
                        padding: '4px',
                        position: 'absolute',
                        right: isItemsAvailable ? '21px' : '4px',
                        bottom: '4px',
                        display: 'flex',
                        gap: '5px',
                        borderRadius: '6px',
                        backgroundColor: '#ffffffdd'
                    }}>
                        {isItemsAvailable ?
                            <IconButton size="small">
                                <FullscreenIcon/>
                            </IconButton> :
                            <></>
                        }

                        <IconButton size="small">
                            <AddPhotoAlternateIcon/>
                        </IconButton>

                        {/* DELETE BUTTON SHOW UP */}

                        {selectedKeys.length > 0 ?
                            <IconButton size="small">
                                <DeleteIcon/>
                            </IconButton> :
                            <></>
                        }
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}

