import FloatingBoard from '../../../gui-set/FloatingBoard';

/* Material UI */
import {
    Box,
    Button,
    Typography
} from '@mui/material';

function DeleteExecution() {
    // props.floatingComponentState.show(
    //     <FloatingBoard sx={{
    //         width: '250px',
    //         height: props.isContaining ? '200px' : '150px',
    //         left: 'calc(50vw - 125px)',
    //         top: `calc(50vh - 150px)`,
    //         padding: '0 20px 0 20px',
    //         justifyContent: props.isContaining ? 'center' : 'space-evenly',
    //         gap: props.isContaining ? '25px' : '0'
    //     }}>
    //         <Typography variant="body1">Pindahkan ke sampah?</Typography>
    //         {props.isContaining ?
    //             <Typography
    //                 variant="caption"
    //                 color="red"
    //                 align="center"
    //             >
    //                 Tindakan ini juga berlaku untuk semua tanda letak yang ada di dalam.
    //             </Typography>
    //             : <></>
    //         }
    //         <Box sx={{ display: 'flex', gap: '40px' }}>
    //             <Button
    //                 size="small"
    //                 variant="contained"
    //                 color="error"
    //                 onClick={() => {
    //                     props.floatingComponentState.remove();
    //                     props.cardsOpenStatus.dropper( props.depth, props.isContaining );
    //                     props.checkBoxStatus.dropper( props.depth, props.isContaining, props.placemark );
    //                     props.placemark.remove( props.placemarksDataState );
    //                 }}
    //             >
    //                 Hapus
    //             </Button>
    //             <Button
    //                 size="small"
    //                 variant="contained"
    //                 onClick={() => {
    //                     props.floatingComponentState.remove();
    //                 }}
    //             >
    //                 Batal
    //             </Button>
    //         </Box>
    //     </FloatingBoard>,
    //     true
    // );
}

export default DeleteExecution;

