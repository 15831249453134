import * as React from 'react';
import './Options.css';

/* Components */
import Account from './options/Account';
import Draw from './options/Draw';
import Versatile from './options/Versatile';

/**
 * @param {object} props 
 * @param {object} props.domSet 
 * @param {object} props.floatingContainer 
 * @param {object} props.drawState 
 * @param {object} props.shadeShieldGlobalState 
 * @param {object[]} props.selectedPlacemarkState - react 'useState' pair
 * @param {Function} props.setPlacemarksImport 
 * @param {Function} props.setImportStatus 
 * @param {object} props.formInputValue - react 'useRef'
 * @param {object} props.formInputValue.special 
 * @param {object} props.formInputValue.general 
 * @param {Function} props.increaseFormInputChanged 
 * @param {object} props.fullyDisplayedState 
 * @returns {object} React Component
 */
export default function Options(props) {
    const container = React.useRef(null);

    React.useEffect(() => {
        props.domSet.current.add(
            'Options',
            container.current
        );
    }, []);

    /* Available values: 'details', 'form', 'import' */
    const [versatileMode, setVersatileMode] = React.useState('details');

    const [drawType, setDrawType] = React.useState('');

    return <div
        ref={container}
        className={
            props.fullyDisplayedState.value ?
            "Options" : "Options-Float"
        }
    >
        <Account
            domSet={props.domSet}
            floatingContainer={props.floatingContainer}
            shadeShieldGlobalState={props.shadeShieldGlobalState}
            optionsFullyDisplayedState={props.fullyDisplayedState}
        />
        {props.fullyDisplayedState.value ?
            <>
                <Draw
                    domSet={props.domSet}
                    setVersatileMode={setVersatileMode}
                    setType={setDrawType}
                    drawState={props.drawState}
                    shadeShieldGlobalState={props.shadeShieldGlobalState}
                    setPlacemarksImport={props.setPlacemarksImport}
                    setImportStatus={props.setImportStatus}
                />
                <Versatile
                    domSet={props.domSet}
                    modeState={{ value: versatileMode, setter: setVersatileMode }}
                    drawType={drawType}
                    shadeShieldGlobalState={props.shadeShieldGlobalState}
                    selectedPlacemarkState={props.selectedPlacemarkState}
                    setImportStatus={props.setImportStatus}
                    formInputValue={props.formInputValue}
                    increaseFormInputChanged={props.increaseFormInputChanged}
                />
            </>
            : <></>
        }
    </div>;
}

