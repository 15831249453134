/**
 * Ease 'graphic' function call.
 */
export default class GraphicBundle {
    constructor() {
        /**
         *  0 = Backbone, Backhaul, POP, ODC, Client
         *  1 = Access, ODP, Closure, Handhole, Coil
         *  2 = Pole 
         */
        this.zoomTier = 0;

        /* Google Maps objects */
        this.gmap = null;
        this.infoWindow = null;
        this.graphics = [];
        this.newGraphics = [];
        this.markerClusterer = null;
        this.isAlreadyConcatenate = false;

        /* Relation between 'zoomTier' and 'infoWindow' */
        this.infoWindowZoom = {
            tier: -1,          // follows 'zoomTier' available value
            openAnchor: null,  // can be a 'Marker'
            plcId: ''          // placemark id
        };

        /** On edit/create polyline's path is following dragged vertex. */
        this.vertexPath = [];
        this.vertexArr = [];
    }
};

