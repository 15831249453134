import * as React from 'react';

/* Material UI */
import { Box } from '@mui/material';

/**
 * Title on the left/top and any data on the right/bottom.
 * The design changes to vertical when there is too much data.
 * 
 * @param {object} props 
 * @param {string} props.title 
 * @param {object} props.data 
 * @param {object} props.defaultData 
 * @param {string} props.leftWidth 
 * @param {string} props.rightWidth 
 * @param {string} props.bothWidth 
 * @param {string} props.maxWidth 
 * @returns {object} React Component
 */
export default function DataPairGui(props) {

    let leftWidth = props.leftWidth,
        rightWidth = props.rightWidth;

    if (props.bothWidth) {
        leftWidth = props.bothWidth;
        rightWidth = props.bothWidth;
    }

    const data_DOM = React.useRef(null),
          isVertical = React.useRef(false);

    const [pairDirection, setPairDirection] = React.useState('row');

    React.useEffect(() => {
        // more than 4 lines
        if (data_DOM.current.clientHeight >= 60) { 
            isVertical.current = true;
            setPairDirection('column');
        }
        // maximum about 4 lines in 16px font size
        else {
            isVertical.current = false;
            setPairDirection('row');
        }
    }, [props.data]);

    const baseSx = {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        border: '1px solid #0060ff',
        boxSizing: 'border-box'
    };

    // choose between vertical or horizontal width
    const chooseWidth = (width) => isVertical.current ? props.maxWidth : width;

    return <Box sx={{
        maxWidth: props.maxWidth,
        display: 'flex',
        flexDirection: pairDirection
    }}>
        <Box sx={Object.assign(
            {...baseSx},
            pairDirection === 'row' ?
            {
                minWidth: chooseWidth(leftWidth),
                maxWidth: chooseWidth(leftWidth),
                borderRadius: '5px 0 0 5px',
                borderRight: 'none'
            } :
            {
                borderRadius: '5px 5px 0 0',
                borderBottom: 'none'
            },
            {
                padding: '3px 6px 3px 6px',
                alignItems: 'center',
                backgroundColor: '#0060ff',
                color: 'white',
                fontWeight: 'bold'
            }
        )}>
            {props.title}
        </Box>
        <Box sx={Object.assign(
            {...baseSx},
            pairDirection === 'row' ?
            {
                minWidth: chooseWidth(rightWidth),
                maxWidth: chooseWidth(rightWidth),
                borderRadius: '0 5px 5px 0'
            } :
            { borderRadius: '0 0 5px 5px' },
            { padding: '3px 6px 3px 12px' }
        )}>
            <Box ref={data_DOM}>
                {props.data ? props.data : props.defaultData}
            </Box>
        </Box>
    </Box>;
};

DataPairGui.defaultProps = {
    leftWidth: 'unset',
    rightWidth: 'unset',
    maxWidth: 'unset'
};

