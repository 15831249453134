import DataPairGui from '../../../../gui-set/DataPair';
import SelectGui from '../../../../gui-set/Select';

function ClosureDetails() {
    return (
        <DataPairGui title="Kapasitas" data="12 core"/>
    );
}

function ClosureForm() {
    return (
        <SelectGui
            name="Kapasitas"
            namesList={[
                '6 core',
                '12 core',
                '24 core',
                '48 core',
                '96 core'
            ]}
            noIcon
        />
    );
}

export {
    ClosureDetails,
    ClosureForm
};

