import Placemark from '../../utilities/placemark';
import * as PlacemarkType from '../../utilities/placemark-type';
import ParentChildStatus from '../../utilities/status/parent-child-status';

/**
 * @param {'GM.marker.Marker'} markerGenerator 
 * @param {'GM.core.event.addListener'} eventAdderClick 
 * @param {'useRef.current'} graphicBundle 
 * @param {'{ id, name, type, date, coor, ... }'} placemark 
 * @param {'state hook callback'} setSelectedPlacemark 
 * @param {ParentChildStatus} selectedCardColorStatus 
 * @returns {object} Marker
 */
function createMarker(
    markerGenerator,
    eventAdderClick,
    graphicBundle,
    placemark,
    setSelectedPlacemark,
    selectedCardColorStatus
) {
    const sz = 32;

    const marker = new markerGenerator({
        map: graphicBundle.gmap,
        position: placemark.coor,
        icon: {
            url: placemark.type === 'other' ?
                placemark.client.customIconUrl :
                PlacemarkType.getIconUrl(placemark.type),
            scaledSize: {width: sz, height: sz},
            size: {width: sz, height: sz}
        }
    });

    setClickEvent(
        eventAdderClick,
        graphicBundle,
        marker,
        placemark,
        setSelectedPlacemark,
        selectedCardColorStatus
    );

    return marker;
}

/**
 * @param {'GM.maps.Polyline'} polylineGenerator 
 * @param {'GM.core.event.addListener'} eventAdderClick 
 * @param {'useRef.current'} graphicBundle 
 * @param {'{ id, name, type, date, coor, ... }'} placemark 
 * @param {'state hook callback'} setSelectedPlacemark 
 * @param {ParentChildStatus} selectedCardColorStatus 
 * @returns {object} Polyline
 */
function createPolyline(
    polylineGenerator,
    eventAdderClick,
    graphicBundle,
    placemark,
    setSelectedPlacemark,
    selectedCardColorStatus
) {
    const polyline = new polylineGenerator({
        map: graphicBundle.gmap,
        path: placemark.coor,
        strokeColor: '#00ff00',
        strokeOpacity: 1,
        strokeWeight: 3
    });

    setClickEvent(
        eventAdderClick,
        graphicBundle,
        polyline,
        placemark,
        setSelectedPlacemark,
        selectedCardColorStatus
    );

    return polyline;
}

/**
 * @param {'GM.marker.Marker'} markerGenerator 
 * @param {'GM.core.event.addListener'} eventAdderDrag 
 * @param {'useRef.current'} graphicBundle 
 * @param {'LatLng array'} pathCoors 
 * @param {'polyline object'} modifyPolyline
 * @returns {Array} Markers
 */
function createPolylineVertex(
    markerGenerator,
    eventAdderDrag,
    graphicBundle,
    pathCoors,
    modifyPolyline
) {
    const vertexArr = [];
    graphicBundle.vertexPath = [];

    for (let i = 0; i < pathCoors.length; i++) {

        graphicBundle.vertexPath.push(pathCoors[i]);

        const vertex = new markerGenerator({
            map: graphicBundle.gmap,
            position: pathCoors[i],
            draggable: true,
            icon: {
                url: 'https://raw.githubusercontent.com/bostonsinaga/PK-ContentSamples/main/icons/vertex.png',
                anchor: {x: 5, y: 5},
                scaledSize: {width: 10, height: 10},
                size: {width: 10, height: 10}
            }
        });

        eventAdderDrag(vertex, 'drag', () => {
            graphicBundle.vertexPath[i] = vertex.getPosition();
            modifyPolyline.setPath(graphicBundle.vertexPath);
        });

        vertexArr.push(vertex);
    }

    return vertexArr;
}

/**
 * Call this in placemarks array's map method (as the argument)
 * to create graphics (any type).
 * 
 * Don't forget to filter the undefined placemark that
 * produced by the array's map method with 'ValidateValue.cleanArray'.
 * 
 * @param {object} GM - Google Maps
 * @param {object} graphicBundle 
 * @param {Function} setSelectedPlacemark 
 * @param {ParentChildStatus} selectedCardColorStatus 
 * @param {Function} getZoomTier 
 * @param {boolean} isApproved 
 * @returns {Function} 
 */
function createGraphicsByCallback(
    GM,
    graphicBundle,
    setSelectedPlacemark,
    selectedCardColorStatus,
    getZoomTier,
    isApproved
) {
    return (plc) => {
        if (!plc.client.isPseudo) {
            let graphic;

            // path
            if (plc.type === 'cable') {
                graphic = createPolyline(
                    GM.maps.Polyline,
                    GM.core.event.addListener,
                    graphicBundle.current,
                    plc,
                    setSelectedPlacemark,
                    selectedCardColorStatus
                );
            }
            // pin
            else {
                graphic = createMarker(
                    GM.marker.Marker,
                    GM.core.event.addListener,
                    graphicBundle.current,
                    plc,
                    setSelectedPlacemark,
                    selectedCardColorStatus
                );
            }

            graphic.setVisible(getZoomTier(plc.type) === 0 ? true : false);

            // 'graphic' has custom property object named 'pk_plc'
            plc.client.approved = isApproved;
            graphic.pk_plc = plc;

            return graphic;
        }
    }
}

/**
 * Center the map and set the zoom covers all imported placemarks.
 * 
 * @param {object} GM - Google Maps
 * @param {object} GraphicBundle 
 * @param {object} plc_in 
 */
function centerMap(GM, GraphicBundle, plc_in) {
    var bounds = new GM.core.LatLngBounds();

    for (const plc of plc_in) {
        // path
        if (Array.isArray(plc.coor)) {
            for (const coor_1 of plc.coor) {
                bounds.extend(coor_1);
            }
        }
        // pin
        else bounds.extend(plc.coor);
    }

    GraphicBundle.current.gmap.fitBounds(bounds);
}

/**
 * Actually create a temporary marker.
 * It can be removed with cancel button.
 *
 * @param {object} GM - Google Maps
 * @param {object} graphicBundle 
 * @param {object} drawState 
 * @param {object} mainDataState 
 * @param {Function} setSelectedPlacemark 
 * @param {ParentChildStatus} selectedCardColorStatus 
 * @param {object[]} pathCoors 
 * @returns {void}
 */
function addNewGraphic(
    GM,
    graphicBundle,
    drawState,
    mainDataState,
    setSelectedPlacemark,
    selectedCardColorStatus,
    pathCoors
) {
    const centerMapCoor = graphicBundle.current.gmap.getCenter();

    const plc = new Placemark({
        type: drawState.plcType,
        time: {
            date: '02/10/2023',
            hour: '16:49'
        },
        coor: pathCoors ?
            pathCoors :
            {lat: centerMapCoor.lat(), lng: centerMapCoor.lng()},
        client: {
            placemarkMates: graphicBundle.current.graphics
        }
    });

    // included to 'placemarksData' (will be saved to database)
    mainDataState.pusher(plc);

    //__________|
    // POLYLINE |
    //__________|
    if (pathCoors) {
        const path = createPolyline(
            GM.maps.Polyline,
            GM.core.event.addListener,
            graphicBundle.current,
            plc,
            setSelectedPlacemark,
            selectedCardColorStatus
        );

        graphicBundle.current.vertexArr = createPolylineVertex(
            GM.marker.Marker,
            GM.core.event.addListener,
            graphicBundle.current,
            pathCoors,
            path
        );

        graphicBundle.current.newGraphics.push(path);
    }
    //________|
    // MARKER |
    //________|
    else {
        graphicBundle.current.newGraphics.push(createMarker(
            GM.marker.Marker,
            GM.core.event.addListener,
            graphicBundle.current,
            plc,
            setSelectedPlacemark,
            selectedCardColorStatus
        ));

        // draggable only for marker
        graphicBundle.current.newGraphics[
            graphicBundle.current.newGraphics.length - 1
        ].setDraggable(true);
    }
}

/**
 * @param {string} name
 * @returns {string} Stringified HTML
 */
function getStyledInfoWindowContent(name) {
    return `<b style="margin: 0 13px 0 7px;">
        <i>${name}</i>
    </b>`;
}

/**
 * @param {object} eventAdderClick 
 * @param {object} graphicBundle 
 * @param {object} graphic 
 * @param {Placemark} placemark 
 * @param {Function} setSelectedPlacemark 
 * @param {ParentChildStatus} selectedCardColorStatus 
 */
function setClickEvent(
    eventAdderClick,
    graphicBundle,
    graphic,
    placemark,
    setSelectedPlacemark,
    selectedCardColorStatus
) {
    eventAdderClick(graphic, 'click', () => {

        /* pop up placemark name when it clicked */

        graphicBundle.infoWindow.setContent(
            getStyledInfoWindowContent(placemark.name)
        );

        graphicBundle.infoWindow.open(graphicBundle.gmap, graphic);

        // display placemark data on 'Options'
        if (placemark.client.approved) {
            setSelectedPlacemark(placemark);
        }

        /*
        *   Graphic 'zoomTier' reference for 'graphicBundle.infoWindow'
        *   (when not pop up the value is -1).
        */
        graphicBundle.infoWindowZoom.tier = graphicBundle.zoomTier;
        graphicBundle.infoWindowZoom.openAnchor = graphic;
        graphicBundle.infoWindowZoom.plcId = placemark._id;

        // selectedCardColorStatus.set()
    });
}

export {
    createMarker,
    createPolyline,
    createPolylineVertex,
    createGraphicsByCallback,
    centerMap,
    addNewGraphic,
    getStyledInfoWindowContent
};

