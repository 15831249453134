import * as React from 'react';

export default class RerenderList {
    /**
     * @param {React} current_in - react state hook
     * @param {RerenderList} upward_in - 'this' upward reference
     */
    constructor(current_in, upward_in) {
        this.current = current_in;
        this.upward = upward_in;
    }
};

