import * as ValidateValue from '../utilities/validate-value';

/* Material UI */
import {
    Box,
    Button,
    FormControl
} from '@mui/material';

/**
 * @param {object} props 
 * @param {string} props.name 
 * @param {object} props.icon 
 * @param {string} props.height 
 * @param {string} props.width 
 * @param {string} props.diameter 
 * @param {string} props.variant - "outlined", "contained", or "text"
 * @param {Function} props.onClick 
 * @returns {object} React Component
 */
export default function ButtonGui(props) {

    let width = props.width,
        height = props.height;

    if (props.diameter) {
        width = props.diameter;
        height = props.diameter;
    }

    return (
        <Button
            variant={props.variant}
            sx={{
                maxWidth: width,
                maxHeight: height,
                minWidth: width,
                minHeight: height
            }}
            onClick={ props.onClick }
        >
            {props.name ?
                <FormControl sx={{ flexDirection: 'row', gap: '8px' }}>
                    {props.icon}
                    <Box>{props.name}</Box>
                </FormControl>
                : props.icon
            }
        </Button>
    );
}

ButtonGui.defaultProps = {
    width: 'unset',
    height: 'unset',
    variant: 'outlined'
};

